import React from 'react'

import { Props } from '.'

export default function Instagram({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 552 552"
    >
      <path
        d="M386.878 0c90.515 0 164.156 73.64 164.156 164.156v222.722c0 90.516-73.64 164.156-164.156 164.156H164.156C73.64 551.034 0 477.394 0 386.878V164.156C0 73.64 73.64 0 164.156 0h222.722zM495.6 386.878V164.156c0-60.046-48.677-108.722-108.722-108.722H164.156c-60.045 0-108.722 48.676-108.722 108.722v222.722c0 60.045 48.677 108.722 108.722 108.722h222.722c60.045 0 108.722-48.677 108.722-108.722zM275.517 133c78.584 0 142.517 63.932 142.517 142.516 0 78.584-63.933 142.517-142.517 142.517S133 354.099 133 275.516 196.933 133 275.517 133zm0 229.6c48.094 0 87.083-38.989 87.083-87.083 0-48.095-38.988-87.083-87.083-87.083-48.094 0-87.083 38.988-87.083 87.083s38.988 87.083 87.083 87.083zM418.31 168.22c-18.86 0-34.15-15.29-34.15-34.15s15.29-34.15 34.15-34.15 34.15 15.29 34.15 34.15-15.29 34.15-34.15 34.15z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}
