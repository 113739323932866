import React, { useEffect, useRef, useState } from 'react'

import cs from 'classnames'
import Icon, { IconNames } from '../icons'
import styles from './PopinMoreInfo.module.scss'

interface Props {
  display?: boolean
  overlayClassName?: string
  moreInfo: string
  containerDomRect?: DOMRect
  onClose?(): void
}

export default function Popin({
  display = true,
  overlayClassName,
  moreInfo,
  containerDomRect
}: Props) {
  const [isOpen, setOpen] = useState(false)
  const popinIconRef = useRef<HTMLDivElement>(null)
  const popinRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (window.innerWidth < 769) {
      return
    }
    if (containerDomRect && popinIconRef.current) {
      const popinDomRect = popinIconRef.current.getBoundingClientRect()
      const intervalRight = containerDomRect.right - (popinDomRect.right + 200)
      const intervalLeft = popinDomRect.left - 200 - containerDomRect.left
      if (intervalRight < 0) {
        popinRef.current!.style.transform = `translateX(calc(-50% + ${intervalRight}px)`
      }
      if (intervalLeft < 0) {
        popinRef.current!.style.transform = `translateX(calc(${-intervalLeft}px - 50%)`
      }
    }
  }, [containerDomRect])
  const handleOpen = () => {
    if (window.innerWidth < 769 && !isOpen) {
      setOpen(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
  }

  if (!display) {
    return null
  }
  return (
    <span
      className={cs(styles.popin_info__icon__wrapper, overlayClassName)}
      role="dialog"
      ref={popinIconRef}
      onClick={handleOpen}
    >
      <Icon name={IconNames.MoreInfo} />
      {isOpen && (
        <div
          className={styles.popin_info__overlay}
          onClick={handleClose}
          role="dialog"
        />
      )}
      <div className={styles.popin_info__content} ref={popinRef}>
        <p>{moreInfo}</p>
      </div>
    </span>
  )
}
