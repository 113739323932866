import React from 'react'

import { Props } from '.'

export default function Mail({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 512 512"
    >
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <path d="M511.6,197.6c0-0.8-0.2-1.9-0.5-2.6c-0.8-2.9-2.5-5.5-5.2-7.3l-73.3-50.9V82.2c0-7.2-5.9-13.1-13.1-13.1h-84.3 l-71.7-49.9c-4.5-3.1-10.4-3.1-14.9,0L176.4,69h-84c-7.2,0-13.1,5.9-13.1,13.1v54L5.7,187c-3.6,2.5-5.6,6.5-5.6,10.6 c0,0.1,0,0.1,0,0.2l0.4,284.2c0,3.5,1.4,6.8,3.9,9.3c2.5,2.5,5.8,3.8,9.3,3.8c0,0,0,0,0,0l485.4-0.7c7.2,0,13.1-5.9,13.1-13.1 L511.6,197.6z M432.7,168.7l41.9,29.1L432.7,227V168.7z M256,45.9L289.3,69h-66.8L256,45.9z M105.5,95.3h300.9v149.9l-101,70.2 l-41.2-32c-0.1,0-0.1-0.1-0.2-0.1c-4.6-3.5-11.1-3.6-15.9,0.1l-41.5,32.2l-101.1-69.9V95.3z M79.3,168v59.6l-43.1-29.8L79.3,168z M26.3,222.9l158.7,109.7L26.6,455.3L26.3,222.9z M51.9,468.9l204.3-158.5l203.6,157.9L51.9,468.9z M327.1,332.3l158.3-110 l0.3,233.1L327.1,332.3z" />
        </g>
        <g>
          <path d="M344.8,147.7H167.2c-7.2,0-13.1,5.9-13.1,13.1s5.9,13.1,13.1,13.1h177.5c7.2,0,13.1-5.9,13.1-13.1S352,147.7,344.8,147.7 z" />
        </g>
        <g>
          <path d="M344.8,215.9H167.2c-7.2,0-13.1,5.9-13.1,13.1s5.9,13.1,13.1,13.1h177.5c7.2,0,13.1-5.9,13.1-13.1S352,215.9,344.8,215.9 z" />
        </g>
      </g>
    </svg>
  )
}
