import flatten from 'lodash/flatten'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import Dealer from '../../../vo/Dealer'

export function filterDealerByName(dealerName: string, allDealers?: Dealer[]) {
  const regex = new RegExp(`(${dealerName})`, 'gi')
  if (!allDealers) {
    return []
  }
  return allDealers
    .filter(
      (item: Dealer) =>
        item.s_pve_libelle.toLowerCase().indexOf(dealerName.toLowerCase()) > -1
    )
    .map(item => {
      const dealerNameEle = item.s_pve_libelle.replace(
        regex,
        `<strong>$1</strong>`
      )
      return {
        name: `<p>${dealerNameEle}</p><p>${item.s_pve_adresse1}</p>`,
        value: item
      }
    })
}

export function clusterPoints(dealers: Dealer[]) {
  if (dealers) {
    return dealers.map((dealer: Dealer) => ({
      geometry: {
        coordinates: [
          parseFloat(dealer.f_pve_gps_longitude),
          parseFloat(dealer.f_pve_gps_latitude)
        ],
        type: 'Point'
      },
      properties: {
        category: dealer,
        cluster: false,
        dealerCardId: dealer.s_pvm_code_atelier,
        dealerLng: dealer.f_pve_gps_longitude
      },
      type: 'Feature'
    }))
  }
  return []
}

export function formatClusters(clusters: any, supercluster: any) {
  if (clusters && clusters.length > 0) {
    return clusters.map((cluster: any) => {
      const { cluster: isCluster } = cluster.properties
      if (isCluster) {
        const childs = supercluster.getLeaves(cluster.id)
        const dealerChild = map(childs, 'properties.category')
        const childsId = map(childs, 'properties.dealerLng')
        return { ...cluster, childsId, dealerChild }
      }
      const dealer = [cluster.properties.category]
      return { ...cluster, dealerChild: dealer }
    })
  }
  return []
}

export function extractDealerFromCluster(formattedClusters: any) {
  if (formattedClusters && formattedClusters.length > 0) {
    const listDealer = flatten(
      formattedClusters.map((cluster: any) => cluster.dealerChild)
    )
    return orderBy(listDealer, ['s_pve_libelle'], 'asc')
  }
  return []
}
