import React from 'react'

import { Props } from '.'

export default function Twitter({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 411 342"
    >
      <path
        d="M403.632 40.18a162.414 162.414 0 01-28.28 9.537 88.177 88.177 0 0023.275-37.067c1.295-4.051-3.105-7.554-6.763-5.385a163.188 163.188 0 01-43.235 17.862 11.02 11.02 0 01-2.702.336c-2.766 0-5.455-1.027-7.57-2.891C322.201 8.333 301.422.491 279.849.491c-9.335 0-18.76 1.455-28.014 4.325-28.672 8.893-50.795 32.544-57.736 61.724-2.604 10.945-3.309 21.9-2.097 32.56a3.166 3.166 0 01-.797 2.481 3.278 3.278 0 01-2.753 1.091C125.69 96.841 69.094 66.604 29.089 17.532c-2.04-2.503-5.952-2.196-7.578.593-7.834 13.44-11.974 28.812-11.974 44.454 0 23.972 9.631 46.563 26.36 63.032a79.24 79.24 0 01-20.169-7.808c-3.06-1.7-6.825.485-6.868 3.985-.438 35.612 20.412 67.3 51.646 81.569a79.567 79.567 0 01-16.786-1.399c-3.446-.658-6.341 2.611-5.271 5.952 10.138 31.651 37.39 54.981 70.002 60.278-27.066 18.169-58.585 27.753-91.39 27.753l-10.227-.006c-3.151 0-5.816 2.054-6.619 5.106-.791 3.006.666 6.177 3.353 7.74 36.966 21.513 79.131 32.883 121.955 32.883 37.485 0 72.549-7.439 104.219-22.109 29.033-13.449 54.689-32.674 76.255-57.141 20.09-22.792 35.8-49.103 46.692-78.201 10.383-27.737 15.871-57.333 15.871-85.589v-1.346c-.001-4.537 2.051-8.806 5.631-11.712a174.776 174.776 0 0035.16-38.591c2.573-3.849-1.485-8.673-5.719-6.795z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}
