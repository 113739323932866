import cs from 'classnames'
import { Field } from 'formik'
import React, { ChangeEvent } from 'react'
import ListHandler from '../../layouts/ListHandler'
import CustomErrorMessage from './CustomErrorMessage'
import RadioField from './RadioField'
import styles from './RadioFieldArray.module.scss'

export enum RadioFieldStyleType {
  RADIO = 'radio',
  TABS = 'tabs',
  COLOR = 'color',
  CLASSIC = 'classic'
}

interface Option {
  label: string
  value: string
}

interface Props {
  name: string
  options: Option[]
  required?: boolean
  legend: string
  legendHidden?: boolean
  styleType?: string
  display?: 'grid' | 'center' | 'left'
  maxMonth?: number
  isAllOptionsVisible?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

function RadioFieldArray({
  name,
  options,
  legend,
  legendHidden = false,
  styleType = RadioFieldStyleType.CLASSIC,
  required = true,
  display,
  maxMonth,
  isAllOptionsVisible = false,
  ...props
}: Props) {
  const groupCSSClassnames = cs(styles.group, styles[styleType], {
    [styles.grid]: display === 'grid',
    [styles.center]: display === 'center',
    [styles.left]: display === 'left'
  })
  const legendCSSClassnames = cs({
    [styles.legend]: styleType === RadioFieldStyleType.TABS,
    [styles.legend_radio]: styleType === RadioFieldStyleType.RADIO,
    [styles.legend_color]: styleType === RadioFieldStyleType.COLOR,
    [styles.legend_classic]: styleType === RadioFieldStyleType.CLASSIC,
    [styles.hidden]: legendHidden
  })

  function renderOptions() {
    return options.map((option: Option, index: number) => {
      const isDisabled = maxMonth && index + 1 > maxMonth
      return (
        <Field
          id={option.value}
          key={`${name + option.value}`}
          name={name}
          label={option.label}
          component={RadioField}
          required={required}
          disabled={isDisabled}
          styleType={styleType}
          {...props}
        />
      )
    })
  }

  return (
    <fieldset id={name} name={name} className={styles.fieldset}>
      <legend className={legendCSSClassnames}>{legend}</legend>
      <div className={groupCSSClassnames}>
        {styleType === RadioFieldStyleType.COLOR ? (
          <ListHandler isAllItemsVisible={isAllOptionsVisible}>
            {renderOptions()}
          </ListHandler>
        ) : (
          <>{renderOptions()}</>
        )}
      </div>
      <CustomErrorMessage name={name} />
    </fieldset>
  )
}

export default RadioFieldArray
