export const MIN_YEAR = 1983
export const UNSIGNED_NUMBER_MIN_VALUE = 0
export const ZIPCODE_MAX_VALUE = 99999
export const EMAIL_SUGGESTION_LIST = [
  'gmail.com',
  'outlook.com',
  'icloud.com',
  'hotmail.fr',
  'yahoo.fr'
]
export const INVALID_EMAIL_DOMAINS = [
  'prtnx.com',
  '20minutemail.com',
  '33mail.com',
  '34nm.com',
  'ahem.email',
  'ahem-email.com',
  'mail.ahem.email',
  'anonymbox.com',
  'dispostable.com',
  'emailisvalid.com',
  'emailsensei.com',
  'matapad.com',
  'armyspy.com',
  'cuvox.de',
  'dayrep.com',
  'einrot.com',
  'fleckens.hu',
  'gustr.com',
  'jourrapide.com',
  'rhyta.com',
  'superrito.com',
  'teleworm.us',
  '66.com',
  'gishpuppy.com',
  'guerrillamailblock.com',
  'harakirimail.com',
  'harakirimail.se',
  'haribu.net',
  'incognitomail.org',
  'jetable.org',
  'lazyinbox.com',
  'mailforspam.com',
  'mfsa.ru',
  'email-jetable.fr',
  'mail-temporaire.fr',
  'mailtemporaire.fr',
  'mail-temporaire.com',
  'mailtemporaire.com',
  'easy-trash-mail.com',
  'easytrashmail.com',
  'email-jetable.biz.st',
  'padpub.co.cc',
  'jetable.co.cc',
  'email-jetable.co.tv',
  'mail-jetable.co.tv',
  'padpub.co.tv',
  'jetable.cz.cc',
  'email-jetable.cz.cc',
  'mail-jetable.cz.cc',
  'email-temporaire.cz.cc',
  'mail-temporaire.cz.cc',
  'mail7.io',
  'mailcatch.com',
  'nanozone.net',
  'mailHazard.com',
  'mailHz.me',
  'mailHazard.com',
  'mailHazard.us',
  'zebins.com',
  'zebins.eu',
  'amail4.me',
  'mailimate.com',
  '1stw.com',
  'tonggen.com',
  'binkmail.com',
  'suremail.info',
  'safetymail.info',
  'MailNesia.com',
  'vipmailonly.info',
  'MailNull',
  'mailnull.com',
  'mailsac.com',
  'mailtemp.net',
  'av.mintemail.com',
  'mytempemail.com',
  'thankyou2010.com',
  'noclickemail.com',
  'nospamme.rs',
  'spam.destroyer.email',
  'randomail.net',
  'spambox.us',
  'spamfree24.org',
  'spamgourmet.com',
  'spamspot.com',
  'uroid.com',
  'TempEMail.net',
  'tempinbox.com',
  'DingBone.com',
  'FudgeRub.com',
  'LookUgly.com',
  'SmellFear.com',
  'TempMail',
  'lackmail.ru',
  'TempSky',
  'tempsky.com',
  'ThrowAwayMail.com',
  'throwam.com',
  'Trash-Mail',
  'trash-mail.com',
  'TrashMail.net',
  'kurzpost.de',
  'objectmail.com',
  'proxymail.eu',
  'rcpt.at',
  'trash-mail.at',
  'trashmail.at',
  'trashmail.me',
  'wegwerfmail.de',
  'wegwerfmail.net',
  'wegwerfmail.org',
  'Trashmail.ws',
  'trashmail.ws',
  'YOPMail yopmail.fr',
  'yopmail.net',
  'cool.fr.nf',
  'jetable.fr.nf',
  'nospam.ze.tc',
  'nomail.xl.cx',
  'mega.zik.dj',
  'speed.1s.fr',
  'courriel.fr.nf',
  'moncourrier.fr.nf',
  'monemail.fr.nf',
  'monmail.fr.nf',
  'mail.mezimages.net',
  'yopmail.com'
]
export const LIST_TYPE_SEARCH = {
  Address: 'Adresse',
  Dealer: 'Concessionnaire'
}

export const EXTERIOR_CAR_PART = [
  'Rétroviseur Droit',
  'Rétroviseur Gauche',
  'Pavillon',
  'Capot',
  'Pare-chocs avant',
  'Coffre',
  'Pare-chocs arrière',
  'Aile avant gauche',
  'Porte avant gauche',
  'Bas de caisse gauche',
  'Porte arrière gauche',
  'Aile arrière gauche',
  'Aile avant droite',
  'Porte avant droite',
  'Bas de caisse droit',
  'Porte arrière droite',
  'Aile arrière droite'
]
