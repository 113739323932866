import { FieldProps } from 'formik'
import React from 'react'

import Checkbox from '../inputs/Checkbox'
import CustomErrorMessage from './CustomErrorMessage'

interface Props extends FieldProps {
  label: string
  checked?: boolean
  className?: string
  required?: boolean
  checkboxGroup: boolean
  isLabelHtml?: boolean
}

export default function CheckboxField({
  form,
  field,
  label,
  checked = false,
  checkboxGroup = false,
  ...props
}: Props) {
  const hasError = !!form.errors[field.name] && !!form.touched[field.name]

  return (
    <Checkbox
      label={label}
      checked={checkboxGroup ? checked : field.value}
      hasError={hasError}
      {...form}
      {...field}
      {...props}
    >
      {!checkboxGroup && <CustomErrorMessage name={field.name} />}
    </Checkbox>
  )
}
