import { FieldProps } from 'formik'
import React from 'react'

import CardOfferRadio from './CardOfferRadio'
import { OfferOptions } from './CardOfferRadioFieldArray'
import CustomErrorMessage from './CustomErrorMessage'

interface Props extends FieldProps {
  offer: OfferOptions
  required?: boolean
}

export default function CardOfferRadioField({
  form,
  field,
  offer,
  ...props
}: Props) {
  const hasError = !!form.errors[field.name] && !!form.touched[field.name]
  return (
    <CardOfferRadio
      {...form}
      {...field}
      {...props}
      hasError={hasError}
      offer={offer}
    >
      <CustomErrorMessage name={field.name} style={{ textAlign: 'center' }} />
    </CardOfferRadio>
  )
}
