import React from 'react'

import { Props } from '.'

export default function BottomArrow({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 452 258"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M225.923,257.706 C217.825,257.706 209.728,254.614 203.554,248.443 L9.27,54.157 C-3.089,41.798 -3.089,21.76 9.27,9.406 C21.624,-2.948 41.658,-2.948 54.018,9.406 L225.923,181.321 L397.829,9.412 C410.188,-2.942 430.22,-2.942 442.573,9.412 C454.938,21.766 454.938,41.804 442.573,54.163 L248.292,248.449 C242.115,254.621 234.018,257.706 225.923,257.706 Z" />
      </g>
    </svg>
  )
}
