import cs from 'classnames'
import React from 'react'

import styles from './EstimateModule.module.scss'

interface Props {
  title?: string
  text?: string
  subtitle: string
  step: number
}

const stepValues = ['0', '33', '66', '100']

export default function EstimateModule({
  step = 1,
  title,
  text,
  subtitle
}: Props) {
  const stepValue = stepValues[step - 1]
  const titleClassNames = cs(styles.title, {
    [styles.titleVisible]: stepValue === '4'
  })
  return (
    <div className={styles.wrapper}>
      <h3 className={titleClassNames}>{title}</h3>
      {text && (
        <span
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      <progress value={stepValue} max="100">
        {stepValue}%
      </progress>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  )
}
