import React from 'react'

import { Props } from '.'

export default function Youtube({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 462 327"
    >
      <path
        d="M365.257.393H95.744C42.866.393 0 43.259 0 96.137v134.728c0 52.878 42.866 95.744 95.744 95.744h269.513c52.878 0 95.744-42.866 95.744-95.744V96.137c0-52.878-42.866-95.744-95.744-95.744zm-64.751 169.663l-126.06 60.123c-3.359 1.602-7.239-.847-7.239-4.568V101.607c0-3.774 3.982-6.22 7.348-4.514l126.06 63.881c3.748 1.899 3.683 7.274-.109 9.082z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}
