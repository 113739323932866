import React from 'react'

import { Props } from '.'

export default function Cross({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22 22"
    >
      <g>
        <g>
          <g>
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="20"
              strokeWidth="2"
              d="M1 1l20 20"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="20"
              strokeWidth="2"
              d="M21 1L1 21"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
