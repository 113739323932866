import { ChildComponentProps } from 'google-map-react'
import React from 'react'

import cs from 'classnames'
import styles from './Cluster.module.scss'

interface Props extends ChildComponentProps {
  isCardHover: boolean
  lat: number
  lng: number
  pointCount: number
  onClickCluster?: () => void
}

export default function Marker({
  isCardHover,
  pointCount,
  onClickCluster
}: Props) {
  const clusterStyles = cs(styles.cluster, {
    [styles.cluster__hover]: isCardHover
  })

  return (
    <div
      className={styles.cluster__wrapper}
      role="presentation"
      onClick={onClickCluster}
    >
      <div className={clusterStyles}>{pointCount}</div>
    </div>
  )
}
