import { Field } from 'formik'
import React from 'react'

import CarOfferRadioField from './CardOfferRadioField'
import styles from './CardOfferRadioFieldArray.module.scss'
import CustomErrorMessage from './CustomErrorMessage'

export interface OfferOptions {
  id: number
  label: string
  background_color: string
  finish: string
  value_client: number | string
  image: {
    url: string
  }
  vehicle: {
    label: string
    display_name: string
    media: {
      url: string
    }
    fuel_consumption: string
    emission: string
  }
  legend?: string
}

interface Props {
  name: string
  options: OfferOptions[]
  required?: boolean
  legend: string
}

export default function CardOfferRadioFieldArray({
  name,
  options,
  legend,
  required = true
}: Props) {
  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>{legend}</legend>
      <>
        {options.map((option: OfferOptions) => {
          return (
            <Field
              id={option.id}
              key={option.id}
              name={name}
              label={option.label}
              component={CarOfferRadioField}
              required={required}
              offer={option}
            />
          )
        })}
      </>
      <CustomErrorMessage name={name} />
    </fieldset>
  )
}
