import React from 'react'

import cs from 'classnames'
import Carousel from 'nuka-carousel'
import styles from './CarSlider.module.scss'
import './CarSlider.override.scss'

export interface ItemData {
  s_vim_nom: string
  s_vim_url: string
  bChargerImage: boolean
}
interface Props {
  data?: ItemData[]
  imgWrapperClassName?: string
}

interface Slide {
  currentSlide: number
  slideCount: number
}

const navigateBtnStyle = {
  backgroundColor: 'transparent',
  border: 'solid #4A4A4A',
  borderWidth: '0 2px 2px 0',
  color: 'transparent',
  cursor: 'pointer',
  height: '12px',
  outline: 'none',
  padding: '0',
  width: '12px'
}
const navigateBtnStyleNext = {
  transform: 'rotate(-45deg)'
}
const navigateBtnStylePrev = {
  transform: 'rotate(135deg)'
}

const defaultControlsConfig = {
  containerClassName: styles.car_slider__container,
  nextButtonClassName: styles.car_slider__next,
  nextButtonStyle: { ...navigateBtnStyle, ...navigateBtnStyleNext },
  pagingDotsClassName: styles.car_slider__dot,
  pagingDotsContainerClassName: cs(
    styles.car_slider__dot__container,
    'car__slider__dot'
  ),
  pagingDotsStyle: {
    display: 'inline-block',
    height: '10px',
    opacity: '1',
    outline: 'none',
    padding: '0',
    width: '10px'
  },
  prevButtonClassName: styles.car_slider__prev,
  prevButtonStyle: { ...navigateBtnStyle, ...navigateBtnStylePrev }
}

export default function CarSlider({ data, imgWrapperClassName }: Props) {
  const renderCardItems = () => {
    if (!data) {
      return
    }
    return data.map((item, index) => (
      <div
        key={item.s_vim_url}
        className={cs(styles.car_slider__image__wrapper, imgWrapperClassName)}
      >
        <div
          key={`Slide ${index + 1}`}
          className={styles.car_slider__image}
          style={{ backgroundImage: `url(${item.s_vim_url})` }}
        />
      </div>
    ))
  }
  const renderBottomCenterControls = ({ currentSlide, slideCount }: Slide) => (
    <div className={styles.countSlide}>{`${currentSlide +
      1} / ${slideCount}`}</div>
  )

  return (
    <div className={styles.car_slider}>
      <Carousel
        className={styles.car_slider__wrapper}
        defaultControlsConfig={defaultControlsConfig}
        scrollMode="remainder"
        slidesToScroll={1}
        cellAlign="center"
        slideWidth={1}
        wrapAround={true}
        renderBottomRightControls={renderBottomCenterControls}
      >
        {renderCardItems()}
      </Carousel>
    </div>
  )
}
