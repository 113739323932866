import cs from 'classnames'
import React, { Suspense } from 'react'
import dealer_img from '../../../static/assets/skoda_dealer.jpg'
import Button, { ButtonTypes } from '../buttons/Button'
import styles from './CardDealer.module.scss'

interface Client {
  i_cma_marque_id: number
  s_cma_marque_libelle: string
  s_pvm_code_distributeur: string
  s_pvm_code_atelier: string
}
interface DataTrackingEvent {
  dataEvtAction: string
  dataEvtCategory: string
  dataEvtLabel: string
  dataEvtEvent?: string
}

interface Dealer {
  i_pve_id?: number
  gen_s_cli_id?: string
  s_pve_libelle: string
  s_pve_adresse1: string
  s_pve_cp: string
  s_pve_ville: string
  s_pve_region: string
  s_pve_email: string
  s_pve_telephone: string
  s_pve_siret: string
  s_pve_google_map: string
  f_pve_gps_longitude: string
  f_pve_gps_latitude: string
  b_pve_ouverture_journee_lundi?: boolean
  b_pve_ouverture_journee_mardi?: boolean
  b_pve_ouverture_journee_mercredi?: boolean
  b_pve_ouverture_journee_jeudi?: boolean
  b_pve_ouverture_journee_vendredi?: boolean
  b_pve_ouverture_journee_samedi?: boolean
  b_pve_ouverture_journee_dimanche?: boolean
  t_pve_ouverture_debut_matin_lundi?: string
  t_pve_ouverture_fin_matin_lundi?: string
  t_pve_ouverture_debut_apres_midi_lundi?: string
  t_pve_ouverture_fin_apres_midi_lundi?: string
  t_pve_ouverture_debut_matin_mardi?: string
  t_pve_ouverture_fin_matin_mardi?: string
  t_pve_ouverture_debut_apres_midi_mardi?: string
  t_pve_ouverture_fin_apres_midi_mardi?: string
  t_pve_ouverture_debut_matin_mercredi?: string
  t_pve_ouverture_fin_apres_midi_mercredi?: string
  t_pve_ouverture_debut_matin_jeudi?: string
  t_pve_ouverture_fin_matin_jeudi?: string
  t_pve_ouverture_debut_apres_midi_jeudi?: string
  t_pve_ouverture_fin_apres_midi_jeudi?: string
  t_pve_ouverture_debut_matin_vendredi?: string
  t_pve_ouverture_fin_matin_vendredi?: string
  t_pve_ouverture_debut_apres_midi_vendredi?: string
  t_pve_ouverture_fin_apres_midi_vendredi?: string
  t_pve_ouverture_debut_matin_samedi?: string
  t_pve_ouverture_fin_apres_midi_samedi?: string
  aCClientMarqueDTO?: Client[]
}

const CardDealerImg = React.lazy(() => import('./Image'))

interface Props {
  dataTrackingEvent?: DataTrackingEvent
  dealer: Dealer
  isHover: boolean
  handleDealerData(dealer: Dealer): void
}

const BUTTON_LABEL = 'Sélectionner'

export default function CardDealer({
  dealer,
  handleDealerData,
  isHover,
  dataTrackingEvent
}: Props) {
  const cardClassNames = cs(styles.card, {
    [styles.card__hover]: isHover
  })

  const loadingImg = (
    /* TODO : use an image with blur effect as placeholder */
    <div className={styles.loadingImg} />
  )

  const handleClick = () => handleDealerData(dealer)

  return (
    <div className={cardClassNames}>
      <h3 className={styles.title}>{dealer.s_pve_libelle}</h3>

      <Suspense fallback={loadingImg}>
        <CardDealerImg
          url={dealer_img || dealer.s_pve_google_map}
          alt={dealer.s_pve_libelle + '-dealer'}
          width="100%"
          height={144}
        />
      </Suspense>
      <div className={styles.contact_container}>
        <div className={styles.contact}>
          <p>{dealer.s_pve_adresse1}</p>
          <p>
            {dealer.s_pve_ville}&nbsp;-&nbsp;{dealer.s_pve_telephone}
          </p>
        </div>
        <Button
          label="choose_dealer"
          types={ButtonTypes.PRIMARY}
          onClick={handleClick}
          {...dataTrackingEvent}
        >
          {BUTTON_LABEL}
        </Button>
      </div>
    </div>
  )
}
