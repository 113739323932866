import React from 'react'

import cs from 'classnames'
import Button, { ButtonTypes } from '../buttons/Button'
import DisplayText from '../typography/DisplayText'
import Heading from '../typography/Heading'
import styles from './DealerModule.module.scss'

interface Props {
  title: string
  legend: string
  dealer?: string
  isReadOnly?: boolean
  className?: string
  text: {
    change: string
    select: string
  }
  onClick?: () => void
}

export default function DealerModule({
  title,
  legend,
  dealer,
  className,
  isReadOnly = false,
  text: { select, change },
  onClick
}: Props) {
  return (
    <div className={cs(styles.wrapper, className)}>
      <Heading level="3" color="gray">
        {title}
      </Heading>
      <DisplayText
        color="gray"
        textAlign="left"
        size="xxs-small"
        textUppercase={true}
      >
        {dealer ? dealer : legend}
      </DisplayText>
      {!isReadOnly && (
        <Button
          label={dealer ? change : select}
          types={ButtonTypes.DEFAULT}
          fullWidth
          onClick={onClick}
          align="left"
        >
          {dealer ? change : select}
        </Button>
      )}
    </div>
  )
}
