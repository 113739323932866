import { Link } from '@reach/router'
import cs from 'classnames'
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'

import ReactDOM from 'react-dom'
import logo from '../../../static/assets/skoda_logo.svg'
import Heading from '../typography/Heading'
import styles from './NavBar.module.scss'

interface NavBarItem {
  icon: string
  link: string
  title: string
}

interface Props {
  links: NavBarItem[]
  homeSlug?: string
  alertMessage?: string
  children?: any
  className?: string
  handleHome?(): void
}

interface PopinAlertProps {
  onClose: () => void
  text: string
}

export default function NavBar({
  handleHome,
  homeSlug,
  links,
  alertMessage,
  className,
  children
}: Props) {
  const [display, showMenu] = useState(false)
  const [isAlert, showMobileAlert] = useState(false)
  const toggle = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      showMenu(!display)
    },
    [display]
  )
  useEffect(() => {
    if (!!alertMessage) {
      showMobileAlert(true)
    }
  }, [alertMessage])
  const closeAlertHanlder = () => {
    showMobileAlert(false)
  }
  const menuCSSClassnames = cs({
    [styles.menu]: display === false,
    [styles.menu_open]: display === true
  })
  const navbarStyles = cs(styles.navigation, className)
  return (
    <nav className={navbarStyles}>
      {!!alertMessage && isAlert && (
        <div className={styles.banner}>
          <div
            className={styles.alert_message}
            dangerouslySetInnerHTML={{ __html: alertMessage }}
          />
          <span
            className={styles.banner__closeIcon}
            onClick={closeAlertHanlder}
            role="button"
          >
            &times;
          </span>
          <PopinAlert onClose={closeAlertHanlder} text={alertMessage} />
        </div>
      )}
      {handleHome ? (
        <div role="button" onClick={handleHome} className={styles.logo}>
          <Heading>
            <img src={logo} className={styles.logo_img} alt="skoda_logo" />
          </Heading>
        </div>
      ) : (
        <Link to={homeSlug} className={styles.logo}>
          <Heading>
            <img src={logo} className={styles.logo_img} alt="skoda_logo" />
          </Heading>
        </Link>
      )}
      {!!links.length && (
        <>
          <div role="button" onClick={toggle} aria-label="menu_button">
            <input
              className={styles.menu_btn}
              type="checkbox"
              id="menu_btn"
              aria-checked={display}
              checked={display}
              readOnly={true}
              onChange={toggle}
            />
            <label htmlFor="menu_btn" className={styles.menu_icon}>
              <span className={styles.navicon} />
              <span className={styles.label}>Menu</span>
            </label>
          </div>
          <ul className={menuCSSClassnames}>
            {children
              ? children
              : links.map(({ title, link }) => (
                  <li key={title}>
                    <a
                      className={styles.navLink}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {title}
                    </a>
                  </li>
                ))}
          </ul>
        </>
      )}
    </nav>
  )
}

const PopinAlert = ({ onClose, text }: PopinAlertProps) => {
  return ReactDOM.createPortal(
    // tslint:disable-next-line:jsx-wrap-multiline
    <>
      <div className={styles.popin__overlay} />
      <div className={styles.popin__wrapper} aria-modal={true} role="dialog">
        <button
          type="button"
          className={styles.popin__closeButton}
          aria-label="close"
          onClick={onClose}
        >
          <span className={styles.popin__closeIcon}>&times;</span>
        </button>
        <p>
          <span
            className={styles.popin__alert_message__mobile}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </p>
      </div>
    </>,
    document.body
  )
}
// const BannerAlert = ({onClose, text}: )
