import cs from 'classnames'
import React from 'react'
import styles from './CardVehicle.module.scss'

export interface VehicleInformation {
  label: string
  value: string | number
}

export interface Props {
  media: string
  infos: VehicleInformation[]
  containerClassName?: string
  figureClassName?: string
  subtextClassName?: string
}

export default function CardVehicle({
  infos,
  media,
  containerClassName,
  figureClassName,
  subtextClassName
}: Props) {
  const containerStyles = cs(styles.container, containerClassName)
  const figureStyles = cs(styles.figure, figureClassName)
  const subtextStyles = cs(styles.subtext, subtextClassName)
  return (
    <article className={styles.card}>
      <div className={containerStyles}>
        <figure className={figureStyles}>
          <img src={media} alt="vehicle" className={styles.img} />
        </figure>
        <div className={subtextStyles}>
          {infos.map(({ label, value }: VehicleInformation) => {
            return (
              value && (
                <p key={label} className={styles.label}>
                  {label}&nbsp;
                  <span className={styles.value}>{value}</span>
                </p>
              )
            )
          })}
        </div>
      </div>
    </article>
  )
}
