import cs from 'classnames'
import React, { ReactNode } from 'react'

import Heading from '../typography/Heading'
import styles from './Section.module.scss'

interface Props {
  title: string
  children: ReactNode
  className?: string
  type?: string
}

export default function Section({ title, children, className, type }: Props) {
  const sectionCSSClassnames = cs(styles.default, className, {
    [styles.smallSection]: type === 'small'
  })

  return (
    <section className={sectionCSSClassnames}>
      <div className={styles.section_block}>
        <Heading level="1" textAlign="left" ariaLevel="1" color="black">
          {title}
        </Heading>
        <div className={styles.wrapper}>{children}</div>
      </div>
    </section>
  )
}
