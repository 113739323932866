import cs from 'classnames'
import React, { InputHTMLAttributes } from 'react'

import { RadioFieldStyleType } from '../fields/RadioFieldArray'
import styles from './Radio.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string
  hasError?: boolean
  styleType?: string
}

export default function Radio({
  id,
  name,
  label,
  onChange,
  onBlur,
  onFocus,
  value,
  required,
  styleType,
  disabled
}: Props) {
  const isChecked = id === value

  const fieldCSSClassnames = cs({
    [styles.default]: styleType === RadioFieldStyleType.RADIO,
    [styles.tab]: styleType === RadioFieldStyleType.TABS,
    [styles.color]: styleType === RadioFieldStyleType.COLOR,
    [styles.checked]: isChecked,
    [styles.disabled]: disabled
  })
  const gradient = {
    background: id
      ? `linear-gradient(155deg, rgba(255,255,255, .1), ${id} 50%)`
      : '#000000'
  }

  const uniqueId = name + '_' + id.split(' ').join('_')

  return (
    <>
      {styleType === RadioFieldStyleType.CLASSIC ? (
        <div className={styles.classicRadio}>
          <input
            id={uniqueId}
            name={name}
            type="radio"
            checked={isChecked}
            value={id}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            aria-label={id}
            aria-checked={isChecked}
            className={styles.radio}
            required={required}
            disabled={disabled}
          />
          <label htmlFor={uniqueId}>{label}</label>
        </div>
      ) : (
        <label htmlFor={uniqueId} className={fieldCSSClassnames}>
          <input
            id={uniqueId}
            name={name}
            type="radio"
            checked={isChecked}
            value={id}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            aria-label={id}
            aria-checked={isChecked}
            className={styles.input}
            required={required}
            disabled={disabled}
          />
          {styleType === RadioFieldStyleType.COLOR && (
            <span className={styles.roundColor} style={gradient} />
          )}
          {label}
        </label>
      )}
    </>
  )
}
