import { Link } from '@reach/router'
import React, { AnchorHTMLAttributes } from 'react'

import styles from './NavButton.module.scss'

interface NavButton extends AnchorHTMLAttributes<HTMLAnchorElement> {
  url?: string
  path?: string
}

export default function Button({ children, url, path }: NavButton) {
  if (url) {
    return (
      <a
        className={styles.default}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <Link to={path} className={styles.default}>
      {children}
    </Link>
  )
}
