import * as Yup from 'yup'

import required from './required'

export default function postalCode(message: string, requiredMessage?: string) {
  const postalCodeRegExp = /^(([9][7][0-8])|([9][8][0-5]))([0-9]{2})|((0[1-9])|([13-8][0-9])|(2[1-9AB])|(9[0-5]))([0-9]{3})$/
  if (requiredMessage) {
    return required(requiredMessage)
      .matches(postalCodeRegExp, { message })
      .length(5, message)
  }

  return Yup.string()
    .matches(postalCodeRegExp, { message })
    .length(5, message)
}
