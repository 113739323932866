import React from 'react'

import { Props } from '.'

export default function ChevronRightGreen({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg viewBox="0 0 7 10" width={width} height={height} className={className}>
      <path
        fill="none"
        stroke="#4ba82e"
        strokeLinecap="round"
        strokeMiterlimit="20"
        strokeWidth="2"
        d="M1 1v0l4 4v0L1 9v0"
      />
    </svg>
  )
}
