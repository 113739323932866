import React from 'react'

import styles from './ListHandler.module.scss'

interface Props {
  children: any
  itemsVisible?: number
  isAllItemsVisible?: boolean
}

export default function ListHandler({
  children,
  itemsVisible = 8,
  isAllItemsVisible = false
}: Props) {
  if (children && children.length && children.length > itemsVisible) {
    const items = children.filter(
      (_: any, index: number) => index < itemsVisible
    )
    const hiddenItems = children.filter(
      (_: any, index: number) => index >= itemsVisible
    )
    return (
      <div className={styles.default}>
        {items}
        {isAllItemsVisible && hiddenItems}
      </div>
    )
  } else {
    return children
  }
}
