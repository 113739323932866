import debounce from 'debounce-promise'
import { useEffect, useMemo, useState } from 'react'

export default function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const isMobile = useMemo(() => width < 769, [width])

  useEffect(() => {
    const debounceResize = debounce(function handleResize() {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }, 1000)
    window.addEventListener('resize', debounceResize)
    return () => {
      window.removeEventListener('resize', debounceResize)
    }
  })

  return [width, isMobile, height]
}
