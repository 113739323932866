/**
 * Public API surface for common
 */

// Component
export { default as ButtonTracking } from './components/ButtonTracking'

// Tracking
export { default as Tracking } from './tracking'
// @ts-ignore
export {
  ISendEvent,
  TrackingUtilsInterface,
  TrackingValueType
} from './tracking/types'
