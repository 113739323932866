import cs from 'classnames'
import React, { ButtonHTMLAttributes } from 'react'

import { Icon } from '../..'
import styles from './Button.module.scss'

export enum ButtonTypes {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST = 'ghost',
  GHOST2 = 'ghost2',
  LINK = 'link'
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  types?: ButtonTypes
  label: string
  icon?: string
  fullWidth?: boolean
  path?: string
  isBoldText?: boolean
  align?: 'left' | 'right' | 'center'
  padding?: 'normal' | 'small'
  className?: string
}

export default function Button({
  label,
  type = 'button',
  disabled = false,
  types = ButtonTypes.DEFAULT,
  onClick,
  icon,
  fullWidth = false,
  children,
  path,
  isBoldText = false,
  align = 'center',
  padding = 'normal',
  className
}: Props) {
  const buttonClassNames = cs(
    styles[types],
    {
      [styles.fullWidth]: fullWidth,
      [styles.boldText]: isBoldText,
      [styles.left]: align === 'left',
      [styles.right]: align === 'right',
      [styles.center]: align === 'center',
      [styles.centerLink]: types === 'link' && align === 'center',
      [styles.paddingSmall]: padding === 'small'
    },
    className
  )
  const buttonWithIconClassNames = cs({
    [styles.withIcon]: !!icon
  })

  function Children() {
    return (
      <>
        {icon && (
          <Icon
            className={styles.icon}
            width="20px"
            height="20px"
            name={icon}
          />
        )}
        <span className={buttonWithIconClassNames}>{children}</span>
      </>
    )
  }

  if (path) {
    return (
      <a
        type={type}
        className={buttonClassNames}
        style={{ textDecoration: 'none' }}
        href={path}
        aria-label={label}
        target="_blank"
        rel="noopener noreferrer"
        role="button"
      >
        <Children />
      </a>
    )
  }

  return (
    <button
      type={type}
      className={buttonClassNames}
      disabled={disabled}
      onClick={onClick}
      aria-label={label}
    >
      <Children />
    </button>
  )
}
