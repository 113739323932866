import * as Yup from 'yup'

import required from './required'

export default function month(message: string, requiredMessage?: string) {
  const monthRegExp = /^(0?[1-9]|1[012])$/

  if (requiredMessage) {
    return required(requiredMessage).matches(monthRegExp, { message })
  }

  return Yup.string().matches(monthRegExp, { message })
}
