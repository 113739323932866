import React, { FunctionComponent } from 'react'

import { Props as IconProps } from './assets'
import * as Components from './assets'

export enum IconNames {
  Target = 'Target',
  RightArrow = 'RightArrow',
  BottomArrow = 'BottomArrow',
  BackArrow = 'BackArrow',
  NewTyres = 'NewTyres',
  WornOutTyres = 'WornOutTyres',
  ToChangeTyres = 'ToChangeTyres',
  VehicleFront = 'VehicleFront',
  VehicleBack = 'VehicleBack',
  VehicleLeft = 'VehicleLeft',
  VehicleRight = 'VehicleRight',
  Phone = 'Phone',
  Mail = 'Mail',
  Calendar = 'Calendar',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
  Instagram = 'Instagram',
  Cross = 'Cross',
  MoreInfo = 'MoreInfo',
  ChevronRightGreen = 'ChevronRightGreen'
}

type Props = Pick<IconProps, Exclude<keyof IconProps, 'name'>>

export default function Icon({ name, className, ...props }: IconProps) {
  // @ts-ignore
  const Component: FunctionComponent<Props> = Components[name]

  return <Component className={className} {...props} />
}
