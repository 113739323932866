import { Tracking } from '@skoda-laurin/common'
import { Spinner } from '@skoda-laurin/theme'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { render } from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import 'core-js/es'
import TRACKING_VALUE from './tracking/constant'
import TrackingUtils from './tracking/utils'

// lazy load routing component to get a decent first payload
const Core = lazy(() => import('./core/App' /* webpackChunkName: "app" */))

const loadServerMock = () => import('@skoda-laurin/common/src/server')

function App() {
  const [serverMockLoaded, setServerMockLoaded] = useState(
    !process.env.REACT_APP_SERVER_MOCK
  )

  useEffect(() => {
    // Initialize tracking
    Tracking.init(TRACKING_VALUE, TrackingUtils)

    // Tracking
    Tracking.sendPageView({
      'page.Name':
        TRACKING_VALUE.SITENAME + '_' + TRACKING_VALUE.PAGENAME.HOMEPAGE,
      'appweb.Name': '/'
    })

    if (!serverMockLoaded) {
      loadServerMock()
        .then(() => setServerMockLoaded(true))
        // tslint:disable-next-line
        .catch(err => console.error('fail to load server mock', err))
    }
    // eslint-disable-next-line
  }, [])

  if (!serverMockLoaded) {
    return null
  }

  return (
    // use react's Suspense: config, routing and translations might still be loading
    <Suspense fallback={<Spinner />}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY as string}
      >
        <Core />
      </GoogleReCaptchaProvider>
    </Suspense>
  )
}

render(<App />, document.getElementById('trade_in'))
