import { TrackingUtilsInterface } from '@skoda-laurin/common'
import Routes from '../core/constants/Routes'
import { retrieve } from '../core/context/sync'
import TRACKING_VALUE from './constant'

class TrackingUtils implements TrackingUtilsInterface {
  public getPageNameFromPathName(pathname: string) {
    switch (pathname) {
      case Routes.Dispatch:
        return TRACKING_VALUE.PAGENAME.HOMEPAGE
      case Routes.Contact:
        return TRACKING_VALUE.PAGENAME.CONTACT_DETAIL_STEP_2
      case Routes.YourCar:
        return TRACKING_VALUE.PAGENAME.MY_CAR
      case Routes.Colors:
        return TRACKING_VALUE.PAGENAME.COLOR
      case Routes.Details:
        return TRACKING_VALUE.PAGENAME.MORE_DETAIL
      case Routes.Exterior:
        return TRACKING_VALUE.PAGENAME.EXTERNAL_CONDITION
      case Routes.Tyres:
        return TRACKING_VALUE.PAGENAME.TYRES
      case Routes.Options:
        return TRACKING_VALUE.PAGENAME.OPTIONS
      case Routes.Offers:
        return TRACKING_VALUE.PAGENAME.OFFERS
      case Routes.ContactAppointment:
        return TRACKING_VALUE.PAGENAME.RDV_DEALERSHIP_STEP_1
      case Routes.ContactAppointmentInformations:
        return TRACKING_VALUE.PAGENAME.RDV_DEALERSHIP_STEP_2
      case Routes.InServiceDate:
        return TRACKING_VALUE.PAGENAME.DATE_FIRST_REGISTRATION
      case Routes.Brand:
        return TRACKING_VALUE.PAGENAME.BRAND
      case Routes.Vehicle:
        return TRACKING_VALUE.PAGENAME.MODEL
      case Routes.EnergyAndModel:
        return TRACKING_VALUE.PAGENAME.ENGINE
      case Routes.EngineAndMileage:
        return TRACKING_VALUE.PAGENAME.MILEAGE
      default:
        return TRACKING_VALUE.NONE
    }
  }

  /* Format projectKind|wishCar|car|engine|id_offre */
  /* if empty we put hypen. Ex: projectKind|-|-|-|- */
  public getDataValues(demand = retrieve('demand')) {
    const emptyText = '-'
    if (!demand || !Object.keys(demand).length) {
      return `${emptyText}|${emptyText}|${emptyText}|${emptyText}|${emptyText}`
    }
    const values = (demand && demand.form && demand.form.values) || null
    const vehicle = demand && demand.vehicle
    const manual = demand && demand.manual
    const dealer = demand && demand.dealer
    const codeDealer = (dealer && dealer.s_pvm_code_atelier) || emptyText
    const wishCar =
      demand.skodaVehicle || (values && values.wishCar) || emptyText
    const car =
      (vehicle && vehicle.s_veh_modname2) ||
      (manual && manual.vehicle && manual.vehicle.s_veh_modname2) ||
      (values && values.vehicle) ||
      emptyText
    const engine =
      (vehicle && vehicle.s_veh_typtxtfueltypecd2) ||
      (manual && manual.model) ||
      (values && values.model) ||
      emptyText
    const idOffer =
      (demand && demand.selectedOffer && demand.selectedOffer.id) ||
      (values && values.offers) ||
      emptyText

    return `${codeDealer}|${wishCar}|${car.toLowerCase()}|${engine.toLowerCase()}|${idOffer}`
  }

  public getContactType(data: any) {
    const { value } = data
    return value.contact
  }

  public getContactWay(pathContact: string) {
    // ex pathContact: /contact/rdv-concessionnaire
    return pathContact.split('/')[2]
  }
}

export default new TrackingUtils()
