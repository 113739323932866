import React from 'react'

import styles from './BannerMessage.module.scss'

interface Props {
  text?: string
}

export default function BannerMessage({ text }: Props) {
  return (
    <div className={styles.banner_container}>
      <div className={styles.banner_row}>
        <div className={styles.banner_col}>
          <div className={styles.banner_message}>{text}</div>
        </div>
      </div>
    </div>
  )
}
