import React from 'react'

import { Props } from '.'

export default function Facebook({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 409 409"
    >
      <path
        d="M353.701 0H55.087C24.665 0 .002 24.662.002 55.085v298.616c0 30.423 24.662 55.087 55.085 55.087h147.275l.251-146.08h-37.951a8.954 8.954 0 01-8.954-8.92l-.182-47.087a8.955 8.955 0 018.955-8.989h37.882v-45.498c0-52.8 32.247-81.55 79.348-81.55h38.65a8.955 8.955 0 018.955 8.955v39.704a8.955 8.955 0 01-8.95 8.955l-23.719.011c-25.615 0-30.575 12.172-30.575 30.035v39.389h56.285c5.363 0 9.524 4.683 8.892 10.009l-5.581 47.087a8.955 8.955 0 01-8.892 7.901h-50.453l-.251 146.078h87.631c30.422 0 55.084-24.662 55.084-55.084V55.085C408.787 24.662 384.124 0 353.701 0z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}
