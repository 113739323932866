import React from 'react'
import Button, { ButtonTypes } from '../buttons/Button'
import closeIcon from './../../../static/assets/close.png'
import dealerImg from './../../../static/assets/dealer.png'
import styles from './DealerScreenCard.module.scss'

function DealerScreenCard({
  dealer,
  onClickSelect,
  dealerStatus,
  googleMapLinkText,
  buttonSelectText,
  onClick
}: any) {
  const googleMapUrl = 'https://www.google.com/maps/dir'
  return (
    <div className={styles.dealer__card__wrapper}>
      <div className={styles.dealer__cardContainer}>
        <img
          onClick={onClick}
          src={closeIcon}
          alt="close"
          className={styles.dealer__cardContainer__close}
          role="img"
        />
        <img
          alt="dealer"
          src={dealerImg}
          className={styles.dealer__image}
          role="img"
        />
        <div className={styles.dealer__card}>
          <div className={styles.dealer__card__status}>
            <div className={styles.dealer__card__status__marker} />
            <span className={styles.dealer__card__status__text}>
              {dealerStatus}
            </span>
          </div>
          <div className={styles.dealer__card__heading}>
            <h3 className={styles.dealer__card__title}>
              {dealer.s_pve_libelle}
            </h3>
          </div>

          <div className={styles.dealer__card__address}>
            <div>{dealer.s_pve_adresse2}</div>
            <div>{dealer.s_pve_adresse1}</div>
          </div>
          <div className={styles.dealer__card__groupBtn}>
            <Button
              label={'sélectionner'}
              align="left"
              types={ButtonTypes.SECONDARY}
              className={styles.dealer__card__groupBtn__btn}
              onClick={() => onClickSelect()}
            >
              {buttonSelectText}
            </Button>
            <a
              rel="noopener noreferrer"
              className={styles.dealer__card__groupBtn__link}
              target="_blank"
              href={`${googleMapUrl}/(${dealer.f_pve_gps_latitude},${dealer.f_pve_gps_longitude})`}
            >
              {googleMapLinkText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DealerScreenCard
