import { Button, ButtonTypes } from '@skoda-laurin/theme'

import React, { ButtonHTMLAttributes } from 'react'
import Tracking from '../tracking'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  types?: ButtonTypes
  label: string
  icon?: string
  fullWidth?: boolean
  path?: string
  isBoldText?: boolean
  align?: 'left' | 'right' | 'center'
  padding?: 'normal' | 'small'
  className?: string
  dataEvtCategory?: string
  dataEvtAction?: string
  dataEvtLabel?: string
  dataEvtEvent?: string
  dataEvt?: any
  appWebName?: string
}

const { getPageNameFromPathName } = Tracking.utils || {}

export default function ButtonTracking({
  dataEvtCategory,
  dataEvtAction,
  dataEvtLabel,
  dataEvtEvent = 'trackEvent',
  dataEvt,
  appWebName,
  onClick,
  ...props
}: Props) {
  function getPageName() {
    if (!getPageNameFromPathName) {
      return ''
    }
    const { pathname } = document.location
    const pageName = getPageNameFromPathName(pathname)
    if (
      Tracking.TRACKING_VALUE &&
      Tracking.TRACKING_VALUE.SITENAME === 'reprise'
    ) {
      return `${Tracking.TRACKING_VALUE.SITENAME}_${pageName}`
    }
    return pageName
  }

  function handleClick(evt: any) {
    const event = {
      event: dataEvtEvent,
      eventAction: dataEvtAction,
      eventCategory: dataEvtCategory,
      eventLabel: dataEvtLabel && dataEvtLabel.toLowerCase(),
      'page.Name': getPageName(),
      ...dataEvt,
      'appweb.Name': appWebName
    }
    Tracking.sendEvent(event)

    if (typeof onClick === 'function') {
      onClick(evt)
    }
  }

  return <Button onClick={handleClick} {...props} />
}
