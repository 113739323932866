import { TrackingValueType } from '@skoda-laurin/common'

const TRACKING_VALUE: TrackingValueType = {
  ACTION: {
    BEGIN: 'begin',
    BEGIN_WITHOUT_PLATE: 'begin|plate:false',
    BEGIN_WITH_PLATE: 'begin|plate:true',
    CONFIRM: 'confirm',
    CONTACT: 'contact',
    FINISH: 'finish',
    NEXT: 'next',
    NEXT_WITHOUT_PLATE: 'next|plate:false',
    NEXT_WITH_PLATE: 'next|plate:true',
    RESET: 'reset',
    SELECT_OFFER: 'select_offer'
  },
  BID: 995,
  ENV: {
    DEV: 'https://dev.reprise.skoda.ddb.paris',
    PREPROD: 'https://v2-preprod-reprise-skoda.as44099.com',
    PROD: 'https://reprise.skoda.fr'
  },
  EVENT_CATEROGY_PREFIX: 'Microsite - reprise',
  LABEL: {
    UNKNOWN_VEHICLE: 'vehicule inconnu'
  },
  LEIB: 995,
  NONE: 'none',
  PAGENAME: {
    BE_RECALL: 'be_recall',
    BRAND: 'brand',
    COLOR: 'color',
    CONFIRMATION: 'confirmation',
    CONTACT_DETAIL_STEP_1: 'contact_details_step_1',
    CONTACT_DETAIL_STEP_2: 'personnal_form_informations',
    DATE_FIRST_REGISTRATION: 'date_first_registration',
    DEALER_WITHOUT_PLATE: 'dealer_selection_without_plate',
    DEALER_WITH_PLATE: 'dealer_selection_with_plate',
    EMAIL_SUMMARY: 'email_summary',
    ENGINE: 'engine',
    EXTERNAL_CONDITION: 'external_condition',
    HOMEPAGE: 'homepage',
    IMPORTED_VEHICLE: 'imported_vehicle',
    MILEAGE: 'mileage',
    MODEL: 'model',
    MORE_DETAIL: 'more_detail',
    MY_CAR: 'my_car',
    OFFERS: 'offers',
    OPTIONS: 'options',
    PARTICULAR_VEHICLE: 'particular_vehicle',
    RDV_DEALERSHIP_STEP_1: 'rdv_dealership_step_1',
    RDV_DEALERSHIP_STEP_2: 'rdv_dealership_step_2',
    TYRES: 'tyres',
    UNKNOWN_VEHICLE: 'unknown_vehicle',
    WRONG_INFORMATION: 'wrong_informations'
  },
  PAGE_LANGUAGE: 'fr-fr',
  SITENAME: 'reprise'
}

export default TRACKING_VALUE
