import { FieldProps } from 'formik'
import React from 'react'

import Radio from '../inputs/Radio'
import CustomErrorMessage from './CustomErrorMessage'

interface Props extends FieldProps {
  id: string
  label: string
  className?: string
  required?: boolean
  styleType?: string
}

export default function RadioField({
  id,
  form,
  field,
  label,
  styleType,
  ...props
}: Props) {
  const hasError = !!form.errors[field.name] && !!form.touched[field.name]

  return (
    <Radio
      {...form}
      {...field}
      {...props}
      id={id}
      label={label}
      hasError={hasError}
      styleType={styleType}
    >
      <CustomErrorMessage name={field.name} style={{ textAlign: 'center' }} />
    </Radio>
  )
}
