import cs from 'classnames'
import React, { InputHTMLAttributes } from 'react'

import CardOffer from '../../cards/CardOffer'
import styles from './CardOfferRadio.module.scss'
import { OfferOptions } from './CardOfferRadioFieldArray'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean
  offer: OfferOptions
}

export default function CardOfferRadio({
  id,
  name,
  onChange,
  onBlur,
  onFocus,
  value,
  required,
  offer
}: Props) {
  const isChecked = id!.toString() === value

  const fieldCSSClassnames = cs(styles.default, {
    [styles.checked]: isChecked
  })
  return (
    <div className={styles.container}>
      <div className={fieldCSSClassnames}>
        <label htmlFor={id}>
          <input
            id={id}
            name={name}
            type="radio"
            checked={isChecked}
            value={id}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            aria-label={id}
            aria-checked={isChecked}
            className={styles.input}
            required={required}
          />
          <CardOffer
            legend={offer.legend}
            label={offer.label}
            model={offer.finish}
            vehicleImg={offer.image ? offer.image.url : null}
            benefit={offer.value_client}
          />
        </label>
      </div>
    </div>
  )
}
