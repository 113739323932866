import React from 'react'

import { Props } from '.'

export default function RightArrow({
  className,
  width = '20px',
  height = '20px'
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_683_1497)">
        <path
          d="M3.557 9.132V10.9H13.774L9.269 15.354L10.357 16.442L16.783 10.016L10.357 3.59L9.269 4.678L13.774 9.132H3.557Z"
          fill="#9E9FA0"
        />
      </g>
      <defs>
        <clipPath id="clip0_683_1497">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
