import React from 'react'

import { Props } from '.'

export default function BackArrow({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_407_23165)">
        <path
          d="M10.731 15.354L6.226 10.9H16.443V9.132H6.226L10.731 4.678L9.643 3.59L3.217 10.016L9.643 16.442L10.731 15.354Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_407_23165">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
