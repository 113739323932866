import React, { Fragment } from 'react'

import VehicleElements from '../../../../trade-in/src/core/vo/VehicleElements'
import CheckboxGroupInline from '../forms/fields/CheckboxGroupInline'
import Icon, { IconNames } from '../icons/Icon'
import styles from './CardVehicleExterior.module.scss'

export interface Props {
  title: string
  vehicleParts: VehicleElements[]
}

export default function CardVehicle({ title, vehicleParts }: Props) {
  function getVehicleIcon(vehicleFace: string): string {
    switch (vehicleFace) {
      case 'Face avant':
        return IconNames.VehicleFront
      case 'Face arrière':
        return IconNames.VehicleBack
      case 'Face gauche':
        return IconNames.VehicleLeft
      case 'Face droite':
        return IconNames.VehicleRight
      default:
        return IconNames.VehicleFront
    }
  }
  return (
    <article className={styles.card}>
      <figure className={styles.figure}>
        <Icon name={getVehicleIcon(title)} className={styles.svg} />
      </figure>
      <div className={styles.title}>{title}</div>
      <div className={styles.vehicleParts}>
        {Object.values(vehicleParts).map(
          (part: VehicleElements, index: number) => {
            const conditions =
              part.a_rub_elements &&
              Object.values(part.a_rub_elements).map(element => ({
                label: element.s_ele_libelle,
                value: JSON.stringify({
                  f_lde_damage_cost: element.f_ass_prix,
                  i_lde_damage_areas_id: part.i_rub_id,
                  i_lde_damage_part_id: element.i_ele_id,
                  i_lde_type: part.i_rub_zone_cliquable,
                  s_lde_damage_areas_libelle: part.s_rub_libelle,
                  s_lde_damage_part_libelle: element.s_ele_libelle
                })
              }))

            return (
              <Fragment key={part.s_rub_libelle}>
                {conditions && (
                  <CheckboxGroupInline
                    position={index + 1}
                    name={part.s_rub_libelle}
                    legend={part.s_rub_libelle}
                    options={conditions}
                    singleOption
                  />
                )}
              </Fragment>
            )
          }
        )}
      </div>
    </article>
  )
}
