// tslint:disable:ordered-imports
import { FieldProps } from 'formik'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay
} from 'react-dates'

import 'moment/locale/fr'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './calendar.scss'

moment.locale('fr')
interface Props extends FieldProps {
  onBlur?: () => void
}
export default function Calendar({ form: { setFieldValue, values } }: Props) {
  const [focusedInput, setFocusedInput] = useState(false)

  const handleChange = (date: Moment | null) => setFieldValue('date', date)

  const handleFocus = () => setFocusedInput(!focusedInput)

  const ArrowLeftIcon = () => (
    <div className="Calendar_nav_arrow Calendar_arrowLeft" />
  )
  const ArrowRightIcon = () => (
    <div className="Calendar_nav_arrow Calendar_arrowRight" />
  )

  return (
    <DayPickerSingleDateController
      date={values.date}
      focused={focusedInput}
      onDateChange={handleChange}
      onFocusChange={handleFocus}
      firstDayOfWeek={1}
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      navPrev={<ArrowLeftIcon />}
      navNext={<ArrowRightIcon />}
      // tslint:disable-next-line: jsx-no-lambda
      isOutsideRange={day =>
        !isInclusivelyAfterDay(
          day,
          moment()
            .add(1, 'day')
            .startOf('day')
        )
      }
    />
  )
}
