import React from 'react'
import ReactDOM from 'react-dom'

import cs from 'classnames'
import { DisplayText } from '../..'
import Button, { ButtonTypes } from '../buttons/Button'
import styles from './Modal.module.scss'

export enum ModalTypes {
  DEFAULT = 'default',
  FIT = 'fit'
}
export enum ButtonCloseTypes {
  TYPE_1 = 'type_1',
  TYPE_2 = 'type_2'
}
interface Props {
  type?: ModalTypes
  typeBtnClose?: ButtonCloseTypes
  buttonLabel?: string
  buttonSecondLabel?: string
  display?: boolean
  showClose?: boolean
  text?: string
  actionLink?: string
  showButton?: boolean
  showSecondButton?: boolean
  isTextHtml?: boolean
  overlayClassName?: string
  wrapperClassName?: string
  modalClassName?: string
  onClickOverlayClose?: boolean
  showDefaultModalStyle?: boolean
  children?: any
  onClick?(): void
  onClickSecondBtn?(): void
  onClose?(): void
  onClickLink?(): void
}

export default function Modal({
  type = ModalTypes.DEFAULT,
  typeBtnClose = ButtonCloseTypes.TYPE_1,
  display = true,
  showClose = true,
  showButton = true,
  showSecondButton,
  buttonLabel,
  buttonSecondLabel,
  text,
  onClick,
  onClickOverlayClose,
  onClickSecondBtn,
  onClose,
  actionLink,
  onClickLink,
  overlayClassName,
  wrapperClassName,
  modalClassName,
  isTextHtml = false,
  showDefaultModalStyle = true,
  children
}: Props) {
  if (!display) {
    return null
  }
  const overlayStyles = cs(overlayClassName, {
    [styles.overlay]: type === ModalTypes.DEFAULT,
    [styles.overlay__fit]: type === ModalTypes.FIT
  })
  const wrapperStyles = cs(wrapperClassName, {
    [styles.wrapper]: type === ModalTypes.DEFAULT,
    [styles.wrapper__fit]: type === ModalTypes.FIT,
    [styles.wrapper__onClose]: typeBtnClose === ButtonCloseTypes.TYPE_2
  })
  const modalStyles = cs(modalClassName, {
    [styles.modal]: type === ModalTypes.DEFAULT,
    [styles.modal__fit]: type === ModalTypes.FIT
  })
  const btnClostStyles = cs(styles.closeButton, {
    [styles.closeButton_2]: typeBtnClose === ButtonCloseTypes.TYPE_2
  })
  return ReactDOM.createPortal(
    // tslint:disable-next-line:jsx-wrap-multiline
    <>
      <div
        className={overlayStyles}
        onClick={
          onClickOverlayClose && type === ModalTypes.FIT ? onClose : undefined
        }
        role="dialog"
      />
      <div className={wrapperStyles} aria-modal={true} role="dialog">
        <div className={modalStyles}>
          {showClose && (
            <button
              type="button"
              className={btnClostStyles}
              aria-label="close"
              onClick={onClose}
            >
              {typeBtnClose === ButtonCloseTypes.TYPE_1 && (
                <span className={styles.closeIcon}>&times;</span>
              )}
              {typeBtnClose === ButtonCloseTypes.TYPE_2 && (
                <span className={styles.closeIcon_2} />
              )}
            </button>
          )}
          {children}
          {showDefaultModalStyle && (
            <>
              {!!text && (
                <DisplayText textAlign="left" size="large" weight="bold">
                  {isTextHtml ? (
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  ) : (
                    text
                  )}
                </DisplayText>
              )}
              <div className={styles.actions}>
                {showButton && !!buttonLabel && (
                  <>
                    <Button
                      types={ButtonTypes.PRIMARY}
                      label={buttonLabel}
                      onClick={onClick}
                      className={
                        showSecondButton ? styles.second__btn : undefined
                      }
                    >
                      {buttonLabel}
                    </Button>
                    {showSecondButton && !!buttonSecondLabel && (
                      <Button
                        types={ButtonTypes.GHOST}
                        label={buttonSecondLabel}
                        onClick={onClickSecondBtn}
                        className={showSecondButton ? styles.second__btn : ''}
                      >
                        {buttonSecondLabel}
                      </Button>
                    )}
                  </>
                )}
                {actionLink && (
                  <Button
                    types={ButtonTypes.GHOST}
                    label={actionLink}
                    onClick={onClickLink}
                  >
                    {actionLink}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>,
    document.body
  )
}
