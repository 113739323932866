import * as Yup from 'yup'

import required from './required'

export default function registration(
  message: string,
  requiredMessage?: string
) {
  const registrationRegExp = /(^[a-zA-Z]{2}-[0-9]{3}-[a-zA-Z]{2}$)|(^[0-9]{3}-[a-zA-Z]{3}-[0-9]{2,3}$)/
  if (requiredMessage) {
    return required(requiredMessage).matches(registrationRegExp, { message })
  }

  return Yup.string().matches(registrationRegExp, { message })
}
