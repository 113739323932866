import cs from 'classnames'
import { FormikErrors, FormikTouched } from 'formik'
import React, { InputHTMLAttributes } from 'react'

import VisuallyHidden from '../../typography/VisuallyHidden'
import PopinMoreInfo from './../../layouts/PopinMoreInfo'
import styles from './Checkbox.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  checked: boolean
  styleType?: CheckboxType
  errors?: FormikErrors<any> | null
  touched?: FormikTouched<any> | null
  isLabelHtml?: boolean
  className?: string
  hasError?: boolean
  moreInfo?: string
  containerDomRect?: DOMRect
}

enum CheckboxType {
  BUTTON = 'button',
  LINE = 'line',
  SQUARE_INLINE = 'square-inline',
  SQUARE_INLINE_MIDDLE = 'square-inline-middle'
}

export default function Checkbox({
  id,
  name,
  label,
  onChange,
  onBlur,
  onFocus,
  checked,
  styleType = CheckboxType.BUTTON,
  isLabelHtml = false,
  className,
  hasError,
  required = false,
  disabled = false,
  moreInfo,
  containerDomRect
}: Props) {
  const inputCSSClassnames = cs(className, {
    [styles.button]: styleType === CheckboxType.BUTTON,
    [styles.line]: styleType === CheckboxType.LINE,
    [styles.square_inline]:
      styleType === CheckboxType.SQUARE_INLINE ||
      styleType === CheckboxType.SQUARE_INLINE_MIDDLE,
    [styles.checked]: checked,
    [styles.disabled]: disabled,
    [styles.has_error]: hasError,
    ['active']: checked && className
  })

  const labelClassName = cs(styles.label, {
    [styles.font_label_line]: styleType === CheckboxType.LINE,
    [styles.font_label_square_inline]:
      styleType === CheckboxType.SQUARE_INLINE ||
      styleType === CheckboxType.SQUARE_INLINE_MIDDLE,
    [styles.font_label_button]: styleType === CheckboxType.BUTTON,
    [styles.label_square_inline_middle]:
      styleType === CheckboxType.SQUARE_INLINE_MIDDLE
  })
  return (
    <div className={inputCSSClassnames}>
      {isLabelHtml ? (
        <label
          htmlFor={id}
          className={labelClassName}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        <label htmlFor={id} className={labelClassName}>
          {label}
        </label>
      )}
      {!!moreInfo && (
        <PopinMoreInfo
          moreInfo={moreInfo}
          containerDomRect={containerDomRect}
        />
      )}

      <VisuallyHidden>
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          aria-checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          required={required}
          disabled={disabled}
        />
      </VisuallyHidden>
    </div>
  )
}
