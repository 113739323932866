const storage =
  process.env.NODE_ENV !== 'production' ? sessionStorage : localStorage

export function persist(storageKey: string, payload: any) {
  // Persist payload in localStorage in production
  // and sessionStorage in dev mode (for convenience)
  storage.setItem(storageKey, JSON.stringify(payload))
}

export function retrieve(storageKey: string) {
  // Retrieve payload from localStorage or sessionStorage
  const value = storage.getItem(storageKey)
  if (value) {
    return JSON.parse(value)
  }

  return undefined
}

export function clear(storageKey: string) {
  // Clear localStorage or sessionStorage
  storage.removeItem(storageKey)
}
