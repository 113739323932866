import * as Yup from 'yup'

export default function date(
  condition: string,
  message: string,
  requiredMessage?: string
) {
  if (requiredMessage) {
    return Yup.date()
      .min(condition, message)
      .required(requiredMessage)
  }

  return Yup.date().min(condition, message)
}
