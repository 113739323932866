import cs from 'classnames'
import { FormikErrors, FormikTouched } from 'formik'
import React, { Fragment, InputHTMLAttributes } from 'react'

import VisuallyHidden from '../../typography/VisuallyHidden'
import styles from './TextInput.module.scss'

type Size = 'default' | 'large' | 'small' | 'xlarge'
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  hasError?: boolean
  isValid?: boolean
  errors?: FormikErrors<any> | null
  touched?: FormikTouched<any> | null
  labelHidden?: boolean
  inputSize?: Size
  optionalMessage?: string
  fieldType?: 'default' | 'primary'
  autoComplete?: string
  width?: string
}

export default function TextInput({
  id,
  name,
  type,
  placeholder,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  children,
  className,
  hasError = false,
  isValid = false,
  inputSize = 'default',
  fieldType = 'default',
  autoComplete,
  required = false,
  labelHidden = false,
  disabled = false,
  optionalMessage,
  pattern,
  min,
  max,
  width
}: Props) {
  const fieldCSSClassnames = cs({
    [styles.fieldDefault]: inputSize === 'default' && fieldType === 'default',
    [styles.fieldLarge]: inputSize === 'large',
    [styles.fieldSmall]: inputSize === 'small',
    [styles.fieldXLarge]: inputSize === 'xlarge',
    [styles.fieldPrimary]: fieldType === 'primary',
    [styles.fieldError]: hasError,
    [styles.fieldValid]: isValid
  })

  const fieldCSSstatus = cs({
    [styles.inputDefault]: fieldType === 'default',
    [styles.fieldAuto]: width === 'auto',
    [styles.error]: hasError,
    [styles.valid]: isValid
  })

  const cssClassNames = cs(styles[inputSize], className, {
    [styles.error]: hasError,
    [styles.valid]: isValid
  })

  const LabelVisibility = labelHidden ? VisuallyHidden : Fragment

  return (
    <div className={fieldCSSstatus}>
      <div className={cssClassNames}>
        <input
          id={id}
          name={name}
          aria-label={label}
          placeholder={placeholder}
          className={fieldCSSClassnames}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
          disabled={disabled}
          required={required}
          pattern={pattern}
          min={min}
          max={max}
        />
        <LabelVisibility>
          <label htmlFor={id} className={styles.labelDefault}>
            {label}
            {!required && optionalMessage && (
              <span className={styles.optional_message}>{optionalMessage}</span>
            )}
          </label>
        </LabelVisibility>
        {!!isValid && fieldType === 'primary' && (
          <span className={styles.fieldPrimaryValid} />
        )}
        {children}
      </div>
    </div>
  )
}
