import * as Yup from 'yup'

import required from './required'

export default function phone(
  message: string,
  requiredMessage?: string,
  fakePhoneMessage?: string
) {
  const phoneRegExp = /^(0[1-9])(\d{8})$/
  const phoneFakeExp = /^((?!0600000000|0700000000|0612345678|0712345678|0698765432|0798765432|0123456789|0198765432|0987654321)[\s\S])*$/

  if (requiredMessage) {
    if (fakePhoneMessage) {
      return required(requiredMessage)
        .matches(phoneRegExp, { message })
        .matches(phoneFakeExp, { message: fakePhoneMessage })
    } else {
      return required(requiredMessage).matches(phoneRegExp, { message })
    }
  }

  return Yup.string().matches(phoneRegExp, { message })
}
