import cs from 'classnames'
import React from 'react'
import Select from 'react-select'
import { Props } from 'react-select/lib/Select'

import './Select.scss'

import styles from './Select.module.scss'

interface Option {
  label: string
  value: string
}

interface SelectProps extends Props {
  isLegend?: boolean
  className?: string
  legend?: string
  classNamePrefix?: string
}

export default function CustomSelect({
  label,
  name,
  hasError,
  options,
  className,
  classNamePrefix = 'Select',
  isLegend = false,
  legend,
  handleMobileChange,
  ...props
}: SelectProps) {
  const SelectCSSClassnames = cs('Select', {
    Select__error: hasError,
    [`${classNamePrefix}`]: classNamePrefix !== 'Select',
    [`${classNamePrefix}__error`]: hasError && !!classNamePrefix
  })

  const SelectGroupStyles = cs(styles.group, className)
  return (
    <div className={SelectGroupStyles}>
      {isLegend ? <legend className={className}>{legend}</legend> : ''}
      <Select
        {...props}
        options={options}
        aria-label={label}
        classNamePrefix={classNamePrefix}
        className={SelectCSSClassnames}
      />
      <select className={styles.mobile} onChange={handleMobileChange}>
        <option aria-selected={false} value="" />
        {(options as Option[]).map(({ label: text, value }) => (
          <option aria-selected={false} key={text} value={value}>
            {text}
          </option>
        ))}
      </select>
    </div>
  )
}
