import autoData from 'autodata'
import { persist, retrieve } from '../helpers/storage'
import {
  ISendEvent,
  ISendPageView,
  TrackingUtilsInterface,
  TrackingValueType
} from './types'

class Tracking {
  public TRACKING_VALUE: TrackingValueType | null = null
  public utils: TrackingUtilsInterface | null = null

  public init(trackingValue: TrackingValueType, utils: TrackingUtilsInterface) {
    this.TRACKING_VALUE = trackingValue
    this.utils = utils
    autoData.init({
      common: {
        initialTags: {
          initialTagsDelay: 5e2
        },
        plugins: {
          eventTracker: {
            attributePrefix: 'data-evt-',
            attributes: ['category', 'action', 'label', 'event'],
            trigger: 'action'
          }
        },
        tms: {
          name: 'gtm',
          sender: (parsedTag: any) => {
            if (!window.dataLayerV2) {
              window.dataLayerV2 = []
            }
            window.dataLayerV2.push(parsedTag)
          }
        }
      }
    })
  }

  public sendEvent(data: ISendEvent) {
    if (!this.TRACKING_VALUE) {
      return
    }
    autoData.sendEvent({
      'importer.BID': this.TRACKING_VALUE.BID,
      LEIB: this.TRACKING_VALUE.LEIB,
      event: 'trackEvent',
      'page.LanguageVersion': this.TRACKING_VALUE.PAGE_LANGUAGE,
      'web.environment': this.getWebEnvironment(),
      ...(this.TRACKING_VALUE as any).EXTRA_DIMS,
      ...data
    })
  }

  public sendPageView(data: ISendPageView, appendStorage: boolean = false) {
    if (!this.TRACKING_VALUE) {
      return
    }
    autoData.sendPageView({
      'importer.BID': this.TRACKING_VALUE.BID,
      LEIB: this.TRACKING_VALUE.LEIB,
      event: 'track-vp',
      'page.LanguageVersion': this.TRACKING_VALUE.PAGE_LANGUAGE,
      'web.environment': this.getWebEnvironment(),
      ...(this.TRACKING_VALUE as any).EXTRA_DIMS,
      ...data
    })
    const dataLayer = window.dataLayerV2.filter(
      (item: any) => item.event === 'track-vp'
    )
    if (appendStorage && retrieve('dataLayer')) {
      dataLayer.unshift(...retrieve('dataLayer'))
    }
    persist('dataLayer', dataLayer)
  }

  private getWebEnvironment() {
    if (!this.TRACKING_VALUE) {
      return
    }
    const { origin } = document.location
    const { NODE_ENV } = process.env
    if (origin.includes(this.TRACKING_VALUE.ENV.PREPROD)) {
      return 'test'
    } else if (NODE_ENV === 'production') {
      return 'prod'
    } else {
      return 'test'
    }
  }
}

export default new Tracking()
