import cs from 'classnames'
import { ChildComponentProps } from 'google-map-react'
import React from 'react'

import styles from './UserMarker.module.scss'

interface Props extends ChildComponentProps {
  isCardHover?: boolean
  lat: number
  lng: number
}

export default function UserMarker({ $hover, isCardHover }: Props) {
  const markerClassNames = cs(styles.marker, {
    [styles.marker_hover]: $hover || isCardHover
  })
  return (
    <div className={styles.wrapper}>
      <div className={markerClassNames} />
    </div>
  )
}
