import { ChildComponentProps } from 'google-map-react'
import React from 'react'
import Dealer from '../../../../trade-in/src/core/vo/Dealer'
import styles from './DealerMarker.module.scss'

interface Props extends ChildComponentProps {
  dealer: Dealer
  isCardHover: boolean
  lat: number
  lng: number
  onClickMarker?: (dealer: Dealer) => void
}

export default function Marker({ onClickMarker, dealer }: Props) {
  return (
    <div
      className={styles.wrapper__marker}
      onClick={() => onClickMarker!(dealer)}
      role="button"
    >
      <svg
        className={styles.marker}
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="53"
        viewBox="0 0 42 53"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.9247 41.4193C35.1468 39.2032 42 30.9019 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 30.9019 6.85324 39.2032 16.0753 41.4193L21 52.5L25.9247 41.4193Z"
          fill="#419468"
        />
      </svg>
      <svg
        className={styles.markerIcon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_649_1038)">
          <path
            d="M12 20.98C17.76 14.96 18.68 13 18.68 10.34C18.68 6.76 15.62 3.72 12 3.72C8.38 3.72 5.32 6.76 5.32 10.34C5.32 13 6.24 14.96 12 20.98ZM12 12.74C10.66 12.74 9.58 11.66 9.58 10.32C9.58 8.98 10.66 7.9 12 7.9C13.34 7.9 14.42 8.98 14.42 10.32C14.42 11.66 13.34 12.74 12 12.74Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_649_1038">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
