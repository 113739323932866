import React from 'react'

import { Props } from '.'

export default function Target({
  className,
  width = '1em',
  height = '1em'
}: Props) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 296 296"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M147.911,167.9105 C158.956695,167.9105 167.911,158.956195 167.911,147.9105 C167.911,136.864805 158.956695,127.9105 147.911,127.9105 C136.865305,127.9105 127.911,136.864805 127.911,147.9105 C127.911,158.956195 136.865305,167.9105 147.911,167.9105 Z M260.583,139.21 C256.372,83.561 212.277,39.449 156.611,35.238 L156.611,8.701 C156.611,3.481 153.131,0 147.91,0 C142.69,0 139.209,3.48 139.209,8.701 L139.209,35.238 C87.8053038,39.1278247 46.2450006,77.0488597 36.8844887,126.687269 C36.1116093,130.785817 35.5582516,134.964247 35.237,139.21 L8.701,139.21 C3.48,139.21 0,142.69 0,147.91 C0,153.13 3.48,156.611 8.701,156.611 L35.238,156.611 C39.449,212.26 83.544,256.372 139.21,260.583 L139.21,287.12 C139.21,292.34 142.69,295.821 147.911,295.821 C153.131,295.821 156.612,292.341 156.612,287.12 L156.612,260.583 C212.261,256.372 256.373,212.277 260.584,156.611 L287.121,156.611 C292.341,156.611 295.822,153.131 295.822,147.91 C295.822,142.69 292.34,139.21 287.12,139.21 L260.583,139.21 Z M156.611,243.164 L156.611,227.515 C156.611,222.295 153.131,218.814 147.91,218.814 C142.69,218.814 139.209,222.294 139.209,227.515 L139.209,243.164 C93.844,238.918 56.901,201.975 52.655,156.61 L68.304,156.61 C73.524,156.61 77.005,153.13 77.005,147.909 C77.005,142.689 73.525,139.208 68.304,139.208 L52.655,139.208 C56.902,93.843 93.845,56.9 139.21,52.654 L139.21,68.303 C139.21,73.523 142.69,77.004 147.911,77.004 C153.131,77.004 156.612,73.524 156.612,68.303 L156.612,52.656 C201.977,56.902 238.92,93.845 243.166,139.21 L227.517,139.21 C222.297,139.21 218.816,142.69 218.816,147.911 C218.816,153.131 222.296,156.612 227.517,156.612 L243.166,156.612 C238.919,201.976 201.976,238.919 156.611,243.164 Z" />
      </g>
    </svg>
  )
}
