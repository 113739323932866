import GoogleMap from 'google-map-react'
import isEmpty from 'lodash/isEmpty'
import React, { Suspense, useEffect, useRef } from 'react'
import Dealer from '../../../../trade-in/src/core/vo/Dealer'
import Spinner from './../layouts/Spinner'
import Cluster from './Cluster'
import Marker from './DealerMarker'
import styles from './GoogleMapReact.module.scss'
import UserMarker from './UserMarker'

interface PositionTypes {
  lat: number
  lng: number
}
interface ClusterPropertiesTypes {
  cluster: boolean
  cluster_id: number
  point_count: number
  point_count_abbreviated: number
}
interface MarkerPropertiesTypes {
  cluster: boolean
  dealerCardId: string | number
  dealerLng: string | number
  category: Dealer
  cluster_id: number
  point_count: number
  point_count_abbreviated: number
}
interface GeometryClusterTypes {
  coordinates: number[]
  type: string
}

interface ClusterTypes {
  type: string
  properties: ClusterPropertiesTypes
  dealerChild: Dealer[]
  geometry: GeometryClusterTypes
  childsId: Array<string | number>
  id: number
}
interface MarkerTypes {
  type: string
  properties: MarkerPropertiesTypes
  dealerChild: Dealer[]
  geometry: GeometryClusterTypes
}

interface OffsetCenterTypes {
  horizontal: number
  verticle: number
}
interface MapProps {
  APIkey: string
  center: PositionTypes
  defaultCenter: PositionTypes
  zoom: number
  clusters?: Array<ClusterTypes | MarkerTypes>
  cardLng?: string | number
  currentUserLocation?: PositionTypes
  offsetCenter?: OffsetCenterTypes
  onChildMouseEnter?(_key: any, childProps: any): void
  onChildMouseLeave?(): void
  handleOnChangeMap?(e: any): void
  onClickCluster?(
    id: number,
    latitude: number | string,
    longitude: number | string
  ): void
  onClickMarker?(dealer: Dealer): void
}

function Map({
  APIkey,
  center,
  defaultCenter,
  zoom,
  offsetCenter,
  onChildMouseEnter,
  onChildMouseLeave,
  handleOnChangeMap,
  clusters,
  onClickCluster,
  onClickMarker,
  cardLng,
  currentUserLocation
}: MapProps) {
  const mapRef = useRef<any>()
  const mapWrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (mapRef.current && offsetCenter) {
      const [offsetHorizon, offsetVerticle] = calculatingWidthHeight(
        mapWrapperRef,
        offsetCenter
      )
      mapRef.current.panBy(offsetHorizon, offsetVerticle)
    }
  }, [center])
  return (
    <div className={styles.wrapper__map} ref={mapWrapperRef}>
      <Suspense fallback={<Spinner />}>
        <GoogleMap
          bootstrapURLKeys={{ key: APIkey }}
          defaultCenter={defaultCenter}
          center={center}
          defaultZoom={1}
          zoom={zoom}
          resetBoundsOnResize
          onChildMouseEnter={onChildMouseEnter}
          onChildMouseLeave={onChildMouseLeave}
          onChange={handleOnChangeMap} // on drag and drop the map
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map
            if (offsetCenter && mapWrapperRef.current) {
              const [offsetHorizon, offsetVerticle] = calculatingWidthHeight(
                mapWrapperRef,
                offsetCenter
              )
              mapRef.current.panBy(offsetHorizon, offsetVerticle)
            }
          }}
          options={{ fullscreenControl: false }}
          hoverDistance={35}
          distanceToMouse={({ x, y }, { x: mouseX, y: mouseY }) => {
            const offsetY = 30
            const offsetX = 5
            return Math.sqrt(
              (x - mouseX - offsetX) * (x - mouseX - offsetX) +
                (y - mouseY - offsetY) * (y - mouseY - offsetY)
            )
          }} // hovering correctly to the custom marker
        >
          {clusters &&
            clusters.map((cluster: any) => {
              const [longitude, latitude] = cluster.geometry.coordinates
              const {
                cluster: isCluster,
                point_count: pointCount
              } = cluster.properties
              if (isCluster) {
                return (
                  <Cluster
                    onClickCluster={() =>
                      onClickCluster &&
                      onClickCluster(cluster.id, latitude, longitude)
                    }
                    pointCount={pointCount}
                    key={longitude}
                    lat={latitude}
                    lng={longitude}
                    isCardHover={cluster.childsId.includes(String(cardLng))}
                  />
                )
              }
              return (
                <Marker
                  onClickMarker={onClickMarker}
                  dealer={cluster.properties.category}
                  key={longitude}
                  lat={latitude}
                  lng={longitude}
                  isCardHover={
                    cluster.properties.category.f_pve_gps_longitude ===
                    String(cardLng)
                  }
                />
              )
            })}
          {currentUserLocation && !isEmpty(currentUserLocation) && (
            <UserMarker
              key={currentUserLocation.lng}
              lat={currentUserLocation.lat}
              lng={currentUserLocation.lng}
            />
          )}
        </GoogleMap>
      </Suspense>
    </div>
  )
}

function calculatingWidthHeight(
  ref: React.RefObject<HTMLDivElement>,
  offsetCenter: OffsetCenterTypes
) {
  if (ref.current) {
    const width = ref.current.offsetWidth
    const height = ref.current.offsetHeight
    const offsetHorizon = width * offsetCenter.horizontal
    const offsetVerticle = height * offsetCenter.verticle
    return [offsetHorizon, offsetVerticle]
  }
  return [0, 0]
}

export default Map
