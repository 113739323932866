import { Field, FieldArray } from 'formik'
import React from 'react'

import cs from 'classnames'
import styles from './CheckboxGroupInline.module.scss'
import CustomErrorMessage from './CustomErrorMessage'
import RadioField from './RadioField'

interface Option {
  label: string
  value: string
  moreInfo?: string
}

interface Props {
  name: string
  options: Option[]
  legend: string
  position: number
  singleOption?: boolean
  showPosition?: boolean
  className?: string
  checkboxClassName?: string
  optionStyles?: string
  containerDomRect?: DOMRect
}

function CheckboxGroupInline({
  position,
  name,
  options,
  legend,
  showPosition = true,
  className
}: Props) {
  const fieldSetStyles = cs(className, styles.fieldset)
  const groupStyles = cs(styles.group)
  return (
    <fieldset id={name} name={name} className={fieldSetStyles}>
      {/* fieldset doesn't work with flexbox */}
      <div className={styles.wrapper}>
        <legend className={styles.legend}>
          {showPosition && <span>{position}</span>}
          {legend}
        </legend>
        <div className={groupStyles}>
          <FieldArray
            name={name}
            // tslint:disable: jsx-no-lambda
            render={() => {
              return (
                <>
                  {options.map((option: Option, index) => {
                    return (
                      // <Field
                      //   id={`${name}.${index}`}
                      //   key={option.value}
                      //   label={option.label}
                      //   moreInfo={option.moreInfo}
                      //   containerDomRect={containerDomRect}
                      //   name={`${name}.${index}`}
                      //   component={CheckboxField}
                      //   checked={fields.includes(option.value)}
                      //   checkboxGroup
                      //   className={checkboxClassName}
                      //   onChange={() => {
                      //     if (!fields.includes(option.value) && singleOption) {
                      //       replace(0, option.value)
                      //     } else if (!fields.includes(option.value)) {
                      //       push(option.value)
                      //     } else {
                      //       remove(id)
                      //     }
                      //   }}
                      //   styleType="button"
                      // />
                      <Field
                        id={option.value}
                        key={option.value}
                        label={option.label}
                        name={`${name}.${index}`}
                        component={RadioField}
                        required={true}
                        disabled={false}
                        styleType="classic"
                      />
                    )
                  })}
                </>
              )
            }}
          />
        </div>
      </div>
      <CustomErrorMessage name={name} />
    </fieldset>
  )
}

export default CheckboxGroupInline
