import { ErrorMessage } from 'formik'
import React, { CSSProperties } from 'react'

import cs from 'classnames'
import styles from './CustomErrorMessage.module.scss'

interface Props {
  name: string
  style?: CSSProperties
  field?: 'default' | 'primary'
}

function CustomErrorMessage({ name, field = 'default', ...props }: Props) {
  const errorStyles = cs({
    [styles.errorMessage]: field === 'default',
    [styles.error__primary]: field === 'primary'
  })
  return (
    <ErrorMessage
      component="span"
      name={name}
      className={errorStyles}
      {...props}
    />
  )
}

export default CustomErrorMessage
