import cs from 'classnames'
import React from 'react'

import styles from './Breadcrumb.module.scss'

interface BreadcrumbValues {
  isCurrentStep: boolean
  isValid: boolean
  title: string
}

interface Props {
  values: BreadcrumbValues[]
  isCompact?: boolean
  handleClickStep?: (item: string) => void
  isClick?: boolean
  itemClassName?: string
  listClasses?: string
}

export default function Breadcrumb({
  values,
  isCompact = false,
  handleClickStep = () => undefined,
  isClick = false,
  itemClassName,
  listClasses
}: Props) {
  const listClassnames = cs(styles.list, listClasses, {
    [styles.listCompact]: isCompact
  })

  const linkClassnames = cs(styles.links, {
    [styles.clickable]: isClick
  })
  const renderBreadcrumb = () => {
    return values.map(value => {
      const itemStyles = cs(itemClassName, styles.item, {
        [styles.done]: value.isValid,
        [styles.current]: value.isCurrentStep === true
      })
      return (
        <ClickableScrumb
          key={value.title}
          className={[linkClassnames, itemStyles]}
          onClick={() => handleClickStep(value.title)}
          isClick={isClick}
        >
          {window.innerWidth > 769 ? (
            <p className={styles.label}>{value.title}</p>
          ) : (
            <div className={itemStyles}>
              <p className={value.isCurrentStep ? styles.active : undefined}>
                {value.title}
              </p>
            </div>
          )}
        </ClickableScrumb>
      )
    })
  }

  return <ul className={listClassnames}>{renderBreadcrumb()}</ul>
}

function ClickableScrumb({ onClick, isClick, children, ...props }: any) {
  if (isClick) {
    return (
      <li {...props} onClick={onClick} role="button">
        {children}
      </li>
    )
  }
  return <li {...props}>{children}</li>
}
