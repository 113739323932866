import React from 'react'

import logo from '../../../static/assets/skoda_logo.svg'
import styles from './CardOffer.module.scss'

export interface Props {
  label: string
  model: string
  benefit: string | number
  vehicleImg: string | null
  legend?: string
}

export default function CardOffer({
  label,
  model,
  vehicleImg,
  benefit,
  legend
}: Props) {
  return (
    <div className={styles.wrapper}>
      <article className={styles.offer}>
        <header className={styles.header}>
          <h2 className={styles.title}>{label}</h2>
          {model && <p className={styles.model}>{model}</p>}
        </header>
        <div className={styles.subtext}>
          <h3 className={styles.benefit}>{benefit}</h3>
          {legend && (
            <h3
              className={styles.legend}
              dangerouslySetInnerHTML={{ __html: legend }}
            />
          )}
        </div>
        <figure className={styles.figure}>
          <img
            src={vehicleImg ? vehicleImg : logo}
            alt={`${label}_${model}`}
            className={styles.img}
          />
        </figure>
      </article>
    </div>
  )
}
