import React, { useRef, useState } from 'react'

import Carousel from 'nuka-carousel'
import CardVehicleItem from './../cards/CardVehicleItem'
import styles from './CardVehicleSelector.module.scss'
import './CardVehicleSelector.override.scss'

export interface ItemData {
  id: string | number
  vehicleTitle: string
  vehicleTitle2nd?: string
  vehicleDescription: string
  vehicleDescription2nd?: string
  colorInfo?: string
  vehiclePicture: string
}
interface AlignObj {
  descHeight?: number
  titleHeight?: number
}
interface Props {
  data?: ItemData[]
  onSelect: (id: string | number) => void
  idActive: string | number
}

const defaultControlsConfig = {
  containerClassName: styles.card_slider__container,
  nextButtonClassName: styles.card_slider__next,
  pagingDotsClassName: styles.card_slider__dot,
  pagingDotsContainerClassName: styles.card_slider__dot__container,
  pagingDotsStyle: {
    outline: 'none'
  },
  prevButtonClassName: styles.card_slider__prev
}

export default function CardVehicleSelector({
  data,
  onSelect,
  idActive
}: Props) {
  const [alignObj, setAlignObj] = useState<AlignObj>({})
  const titleHeight = useRef(0)
  const descHeight = useRef(0)
  const setTitleHeight = (
    heightTitle: number,
    heightDesc: number,
    isLast: boolean
  ) => {
    if (!isLast) {
      if (titleHeight.current < heightTitle) {
        titleHeight.current = heightTitle
      }
      if (descHeight.current < heightDesc) {
        descHeight.current = heightDesc
      }
    }
    if (isLast) {
      setAlignObj(cur => ({
        ...cur,
        descHeight: descHeight.current,
        titleHeight: titleHeight.current
      }))
    }
  }
  const renderCardItems = () => {
    if (!data) {
      return
    }
    return data.map((item, idx) => (
      <CardVehicleItem
        key={item.id}
        id={item.id}
        title={item.vehicleTitle}
        description={item.vehicleDescription}
        title2nd={item.vehicleTitle2nd}
        description2nd={item.vehicleDescription2nd}
        colorInfo={item.colorInfo}
        picture={item.vehiclePicture}
        isActive={idActive === item.id}
        onSelect={onSelect}
        setTitleHeight={setTitleHeight}
        alignObj={alignObj}
        isLast={idx === data.length - 2}
        isAlign={idx !== data.length - 1}
      />
    ))
  }

  return (
    <>
      <div className={styles.wrapper}>{renderCardItems()}</div>
      <div className={styles.card_slider__wrapper}>
        <Carousel
          className={styles.card_slider}
          height="500"
          defaultControlsConfig={defaultControlsConfig}
          scrollMode="remainder"
          slidesToScroll={1}
          cellAlign="center"
          slideWidth={0.78}
        >
          {renderCardItems()}
        </Carousel>
      </div>
    </>
  )
}
