import cs from 'classnames'
import React, { ReactNode } from 'react'

import styles from './DisplayText.module.scss'

type Size = 'xxs-small' | 'xs-small' | 'small' | 'large' | 'x-large'
interface Props {
  children: ReactNode
  size?: Size
  weight?: 'normal' | 'bold' | 'light'
  color?: 'dark-gray' | 'gray' | 'white'
  textUppercase?: boolean
  textAlign?: 'center' | 'left' | 'right'
  className?: string
}

export default function DisplayText({
  size = 'xs-small',
  color = 'dark-gray',
  textAlign = 'center',
  weight = 'normal',
  children,
  textUppercase = false,
  className
}: Props) {
  const displayTextCSSClassnames = cs(className, {
    [styles.xxssmall]: size === 'xxs-small',
    [styles.default]: size === 'xs-small',
    [styles.small]: size === 'small',
    [styles.large]: size === 'large',
    [styles.xlarge]: size === 'x-large',
    [styles.color_gray]: color === 'gray',
    [styles.color_white]: color === 'white',
    [styles.bold]: weight === 'bold',
    [styles.normal]: weight === 'normal',
    [styles.light]: weight === 'light',
    [styles.uppercase]: textUppercase,
    [styles.text_align_center]: textAlign === 'center',
    [styles.text_align_left]: textAlign === 'left',
    [styles.text_align_right]: textAlign === 'right'
  })

  return <p className={displayTextCSSClassnames}>{children}</p>
}
