import * as Yup from 'yup'

import * as Constants from '../../../trade-in/src/core/utils/constants'
import required from './required'

export default function year(message: string, requiredMessage?: string) {
  const thisYear = new Date().getUTCFullYear()
  let pattern = ''
  for (let i = Constants.MIN_YEAR; i <= thisYear; i++) {
    pattern += i === Constants.MIN_YEAR ? i : `|${i}`
  }
  const yearRegExp = new RegExp(pattern)

  if (requiredMessage) {
    return required(requiredMessage).matches(yearRegExp, { message })
  }

  return Yup.string().matches(yearRegExp, { message })
}
