import cs from 'classnames'
import { Field, FieldArray } from 'formik'
import React from 'react'

import CheckboxField from './CheckboxField'
import styles from './CheckboxFieldArray.module.scss'
import CustomErrorMessage from './CustomErrorMessage'

interface Option {
  label: string
  value: string
}

interface Props {
  name: string
  options: Option[]
  styleType?: 'button' | 'line'
}
// tslint:disable: jsx-no-lambda

export default function CheckboxFieldArray({
  name,
  options,
  styleType = 'line'
}: Props) {
  const groupCSSClassnames = cs({
    [styles.buttons]: styleType === 'button',
    [styles.lines]: styleType === 'line'
  })
  return (
    <fieldset className={styles.fieldset}>
      <div className={groupCSSClassnames}>
        <FieldArray
          name={name}
          render={({ push, remove, form }) => {
            const fields: string[] = form.values[name]
            return (
              <>
                {options.map((option: Option, index) => {
                  const id = fields.indexOf(option.value)
                  return (
                    <Field
                      id={`${name}.${index}`}
                      key={option.value}
                      label={option.label}
                      name={`${name}.${index}`}
                      component={CheckboxField}
                      checked={fields.includes(option.value)}
                      checkboxGroup
                      onChange={() =>
                        fields.includes(option.value)
                          ? remove(id)
                          : push(option.value)
                      }
                      styleType={'square-inline'}
                    />
                  )
                })}
              </>
            )
          }}
        />
      </div>
      <CustomErrorMessage name={name} />
    </fieldset>
  )
}
