import cs from 'classnames'
import debounce from 'debounce-promise'
import React, { useEffect, useMemo, useRef, useState } from 'react'
// @ts-ignore
import AsyncSelect from 'react-select/async'
import Dealer from '../../../../trade-in/src/core/vo/Dealer'
import useWindowWidth from '../../hooks/useWindowWidth'
import leftArrowIcon from './../../../static/assets/left-arrow.png'
import searchIcon from './../../../static/assets/search.png'
import './GeolocationInput.scss'
import { filterDealerByName } from './helpers'

const LIST_TYPE_SEARCH = {
  Address: 'Adresse',
  Dealer: 'Concessionnaire'
}

interface City {
  code: string
  name: string
  zip_codes: string
  region_code: string
  department_code: string
  longitude: string
  latitude: string
}

interface Props<T> {
  label: string
  placeholder: string
  selectType?: 'geolocation' | 'field'
  hasError?: boolean
  isDrawerOpen?: boolean
  isDealerScreen?: boolean
  currentSearchType: string
  drawerToggle?: (isDrawerOpen: boolean) => void
  onClickReturn: () => void
  allDealers?: Dealer[]
  noOptionsMessage(): string
  loadingMessage(): string
  getCities(city: string): Promise<City[]>
  onChange(position: T, type: string): void
  onBlur?(): void
  getOptions(searchText: string, type: string): void
  onResetMap(): void
  onChangeType(type: string): void
}

interface InputTypeSearchProps {
  currentSearchType: string
  onChangeType(type: string): void
}

interface ReturnButtonProps {
  isDealerScreen: boolean
  label: string
  onClickReturn: () => void
}

// tslint:disable-next-line: cognitive-complexity no-big-function
export default function GeolocationInput<T>({
  getOptions,
  label,
  placeholder,
  onChange,
  onBlur,
  selectType = 'geolocation',
  isDealerScreen = false,
  isDrawerOpen,
  onClickReturn,
  drawerToggle = () => undefined,
  onChangeType,
  currentSearchType,
  noOptionsMessage,
  loadingMessage,
  getCities,
  allDealers
}: Props<T>) {
  const [value, setValue] = useState()
  const [inputValue, setInputValue] = useState('')
  const [, isMobile] = useWindowWidth()
  const [menuIsOpen, setMenuOpen] = useState(false)
  const [selectKey, changeSelectKey] = useState<number>(
    Math.round(Math.random() * 1000)
  )
  const [autoFocus, setAutoFocus] = useState(false)
  const [defaultAddressOptions, setDefaultAddressOption] = useState<any>()
  const [defaultDealerOptions, setDefaultDealerOption] = useState<any>()
  const geolocationRef = useRef(null)

  const debouncedGetOptions = useMemo(() => debounce(getOptions, 500), [
    getOptions
  ])
  function mapName(option: any): string {
    return option.name.toUpperCase()
  }
  function mapZipCode(option: any): string {
    return option.zip_codes.substring(0, 5)
  }
  function formatOptionLabel(option: any) {
    return (
      <div
        className="geolocation__input__select__option"
        dangerouslySetInnerHTML={{ __html: option.name }}
      />
    )
  }
  function loadOptions(searchText: string, type: string) {
    if (searchText.length < 2) {
      return
    }
    return debouncedGetOptions(searchText, type)
  }
  function handleChange(position: T) {
    setValue(position)
    setInputValue('')
    setMenuOpen(false)
    onChange(position, currentSearchType)
  }
  function handleFocus() {
    setMenuOpen(true)
    if (isMobile && !isDrawerOpen) {
      drawerToggle(true)
    }
    if (!!value) {
      setValue('')
      setInputValue('')
    }
  }

  function onInputChange(text: string, { action }: any) {
    if (action === 'input-blur' || (action === 'menu-close' && !value)) {
      setInputValue(inputValue)
      return inputValue
    } else if (action === 'menu-close' && !!value) {
      setInputValue('')
      setMenuOpen(false)
      return text.toUpperCase()
    } else {
      setInputValue(text.toUpperCase())
      return text.toUpperCase()
    }
  }
  const isAddress = currentSearchType === LIST_TYPE_SEARCH.Address
  const GeolocationCSSClassnames = cs({
    geolocation__input__drawer_open: isDrawerOpen,
    geolocation__select: selectType === 'geolocation'
  })
  const GeolocationCSSClassnamesDealer = cs('geolocation__input__dealer', {
    geolocation__input__drawer_open: isDrawerOpen,
    geolocation__select: selectType === 'geolocation'
  })
  const GeolocationWrapperStyles = cs('geolocation', {
    geolocation__wrapper__drawer__open: isDrawerOpen,
    geolocation__wrapper__dropdown__open: menuIsOpen,
    geolocation__wrapper__mobile: isDealerScreen && isDrawerOpen && isMobile
  })
  useEffect(() => {
    if (currentSearchType === LIST_TYPE_SEARCH.Address && !!inputValue) {
      setDefaultDealerOption(filterDealerByName(inputValue, allDealers))
    } else if (
      currentSearchType === LIST_TYPE_SEARCH.Dealer &&
      !!inputValue &&
      inputValue.length > 2
    ) {
      const data = getCities(inputValue)
      data.then(resolve => {
        setDefaultAddressOption(resolve)
      })
    } else if (!inputValue) {
      changeSelectKey(Math.round(Math.random() * 1000))
      if (menuIsOpen) {
        setAutoFocus(true)
      }
      setDefaultDealerOption([])
      setDefaultAddressOption([])
    } else {
      setDefaultDealerOption([])
      setDefaultAddressOption([])
    }
    // eslint-disable-next-line
  }, [inputValue, allDealers, currentSearchType])
  useEffect(() => {
    changeSelectKey(Math.round(Math.random() * 1000))
  }, [currentSearchType])
  useEffect(() => {
    if (menuIsOpen) {
      setAutoFocus(false)
    }
    // eslint-disable-next-line
  }, [selectKey])
  useEffect(() => {
    function handleClickOutsideInput(event: any) {
      if (
        geolocationRef.current &&
        // @ts-ignore
        !geolocationRef.current.contains(event.target)
      ) {
        setInputValue('')
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutsideInput)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideInput)
    }
  }, [geolocationRef])
  return (
    <div className={GeolocationWrapperStyles} ref={geolocationRef}>
      <ReturnButton
        isDealerScreen={isDealerScreen}
        label={label}
        onClickReturn={onClickReturn}
      />
      {isAddress && (
        <AsyncSelect
          aria-label={label}
          key={selectKey}
          cacheOptions
          autoFocus={autoFocus}
          classNamePrefix="geolocation__input"
          defaultOptions={defaultAddressOptions}
          onInputChange={onInputChange}
          className={GeolocationCSSClassnames}
          loadOptions={(searchText: any) =>
            loadOptions(searchText, LIST_TYPE_SEARCH.Address)
          }
          getOptionLabel={mapName}
          getOptionValue={selectType === 'geolocation' ? mapName : mapZipCode}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          onFocus={handleFocus}
          loadingMessage={loadingMessage}
          inputValue={inputValue}
          menuIsOpen={menuIsOpen}
        />
      )}
      {!isAddress && (
        <AsyncSelect
          aria-label={label}
          key={selectKey}
          defaultOptions={defaultDealerOptions}
          autoFocus={autoFocus}
          classNamePrefix="geolocation__input"
          onInputChange={onInputChange}
          className={GeolocationCSSClassnamesDealer}
          loadOptions={(searchText: any) =>
            loadOptions(searchText, LIST_TYPE_SEARCH.Dealer)
          }
          getOptionValue={selectType === 'geolocation' ? mapName : mapZipCode}
          value={value}
          formatOptionLabel={formatOptionLabel}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          onFocus={handleFocus}
          loadingMessage={loadingMessage}
          inputValue={inputValue}
          menuIsOpen={menuIsOpen}
        />
      )}
      {/* <CancelButton handleCancel={handleCancel} label={label} /> */}
      {menuIsOpen && (
        <InputTypeSelect
          onChangeType={onChangeType}
          currentSearchType={currentSearchType}
        />
      )}
    </div>
  )
}

const ReturnButton = ({
  isDealerScreen,
  label,
  onClickReturn
}: ReturnButtonProps) => {
  return (
    <>
      {isDealerScreen ? (
        <button
          aria-label={label}
          className="geolocation__button--back"
          onClick={onClickReturn}
        >
          <img
            className="geolocation__icon"
            src={leftArrowIcon}
            alt=""
            role="presentation"
          />
        </button>
      ) : (
        <button aria-label={label} className="geolocation__button--search">
          <img
            className="geolocation__icon"
            src={searchIcon}
            alt=""
            role="presentation"
          />
        </button>
      )}
    </>
  )
}

const InputTypeSelect = ({
  onChangeType,
  currentSearchType
}: InputTypeSearchProps) => {
  const renderOption = () =>
    Object.values(LIST_TYPE_SEARCH).map(item => {
      const optionStyles = cs('geolocation__type-option', {
        active: currentSearchType === item
      })
      return (
        <li
          key={item}
          className={optionStyles}
          onClick={() => onChangeType(item)}
          role="button"
        >
          {item}
        </li>
      )
    })

  return <ul className="geolocation__type-select">{renderOption()}</ul>
}
