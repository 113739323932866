import * as Yup from 'yup'

import email from '../../../../validators/email'
import mileage from '../../../../validators/mileage'
import phone from '../../../../validators/phone'
import postalCode from '../../../../validators/postalCode'
import registration from '../../../../validators/registration'
import required from '../../../../validators/required'

export const initialValues = {
  calendarField: {
    date: null
  },
  cardOfferRadioFieldArray: {
    default: 0
  },
  checkboxField: {
    singleCheckbox: false
  },
  checkboxFieldArray: {
    checkbox: []
  },
  radioFieldArray: {
    default: 'oui',
    userCategory: 'Particulier'
  },
  selectField: {
    select: ''
  },
  textField: {
    registration: '',
    text: '',
    textRequired: ''
  }
}

const requiredField = required('Ce champ est requis')

export const validationSchema = {
  calendarField: {
    date: requiredField
  },
  cardOfferRadioFieldArray: Yup.object().shape({
    default: requiredField
  }),
  checkboxField: Yup.object().shape({
    singleCheckbox: Yup.boolean()
  }),
  checkboxFieldArray: Yup.object().shape({
    checkbox: Yup.array()
  }),
  radioFieldArray: Yup.object().shape({
    default: requiredField,
    tab: requiredField
  }),
  selectField: Yup.object().shape({
    select: requiredField
  }),
  textField: Yup.object().shape({
    email: email('Ce champs doit être un email'),
    mileage: mileage('Ce champs doit être un nombre'),
    phone: phone('Ce champs doit être un numéro de téléphone valide'),
    postalCode: postalCode('Ce champs doit être un code postal'),
    registration: registration(
      'Ce champs doit être au format AA-000-AA ou 000-AAA-000'
    ),
    text: Yup.string(),
    textRequired: requiredField
  })
}

export function formatedNumber(value: string) {
  const result = value.toLocaleString().replace(/,/g, '')

  if (isNaN(parseInt(result, 10))) {
    // If the formatted value doesn't result in a number, then return the original value
    return value
  }

  return result
}

export function registrationNumberFormat(message: string) {
  if (!message.includes('-')) {
    const regex = /^([a-zA-Z]{2}|[0-9]{3})([a-zA-Z]{3}|[0-9]{3})([a-zA-Z]{2}|[0-9]{2,3})$/
    const chunks = regex.exec(message)
    if (chunks) {
      return chunks
        .splice(1, 3)
        .join('-')
        .toUpperCase()
    }
  }

  return message.toUpperCase()
}

export function mileageNumberFormat(message: string) {
  const msg = message.replace(/[\D|\s]/g, '')
  const regex = /^[0-9]{1,7}$/
  const chunks = regex.exec(msg)
  if (chunks) {
    return parseInt(msg, 10)
      .toLocaleString()
      .replace(/(,|\.)/g, ' ')
  }

  return ''
}
export function addSuffix(message: string, suffix: string) {
  const expression = `${suffix}$`
  const regex = new RegExp(expression, 'g')
  const chunks = regex.exec(message)
  if (!chunks && message) {
    return message + suffix
  } else if (Number(message) === 0) {
    return ''
  }
  return message
}
export function removeSuffix(message: string, suffix: string) {
  const expression = `${suffix}$`
  const regex = new RegExp(expression, 'g')
  const chunks = regex.exec(message)
  if (chunks) {
    return message.replace(regex, '')
  }
  return message
}

export function closestValue(value: number, array: number[]) {
  return array.reduce((a: number, b: number) => {
    const aDiff = Math.abs(a - value)
    const bDiff = Math.abs(b - value)

    if (aDiff === bDiff) {
      return a > b ? a : b
    } else {
      return bDiff < aDiff ? b : a
    }
  })
}

export function listValueReturn(value: number, array: number[]) {
  return array[value]
}

export function upperFirstCharFormat(message: string) {
  const msg = message.replace(/^\s+/, '')
  return msg.charAt(0).toUpperCase() + msg.slice(1)
}

export function contributionFormat(
  message: number | string,
  max: number | string,
  min: number | string
) {
  const msg = message
  const floorMsg = Math.floor(Number(msg) / 1000) * 1000
  if (Number(msg) === Number(max) || Number(msg) === Number(min)) {
    return msg
  } else {
    return floorMsg < Number(min)
      ? Number(min)
      : floorMsg > Number(max)
      ? Number(max)
      : floorMsg
  }
}

export function emailMatchingList(message: string, suggestionList: string[]) {
  const regex = /^(.+)@{1}(.*)$/
  const chunks = regex.exec(message)
  if (chunks) {
    const [, prefixAt, suffixAt] = chunks
    const filteredList = suffixAt.length
      ? suggestionList.filter(item => item.includes(suffixAt))
      : suggestionList
    return filteredList.map(item => prefixAt + '@' + item)
  } else {
    return []
  }
}
