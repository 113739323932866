/**
 * Public API surface for theme
 */

// Components
export { ButtonTypes, default as Button } from './components/buttons/Button'
export { default as NavButton } from './components/buttons/NavButton'
export { default as CardDealer } from './components/cards/CardDealer'
export { default as CardVehicle } from './components/cards/CardVehicle'
export { default as CardVehicleItem } from './components/cards/CardVehicleItem'
export {
  default as CardVehicleExterior
} from './components/cards/CardVehicleExterior'
export {
  default as CardOfferRadioFieldArray
} from './components/forms/fields/CardOfferRadioFieldArray'
export {
  default as CheckboxField
} from './components/forms/fields/CheckboxField'
export {
  default as CheckboxFieldArray
} from './components/forms/fields/CheckboxFieldArray'
export {
  default as CheckboxGroupInline
} from './components/forms/fields/CheckboxGroupInline'
export { default as RadioField } from './components/forms/fields/RadioField'
export {
  default as RadioFieldArray,
  RadioFieldStyleType
} from './components/forms/fields/RadioFieldArray'
export { default as BannerMessage } from './components/layouts/BannerMessage'
export { default as SelectField } from './components/forms/fields/SelectField'
export { default as TextField } from './components/forms/fields/TextField'
export { default as RangeField } from './components/forms/fields/RangeField'
export {
  default as GeolocationSelect
} from './components/forms/inputs/GeolocationSelect'
export {
  default as GeolocationField
} from './components/forms/fields/GeolocationField'
export { default as Icon, IconNames } from './components/icons'
export { default as DealerModule } from './components/layouts/DealerModule'
export { default as EstimateModule } from './components/layouts/EstimateModule'
export { default as ListHandler } from './components/layouts/ListHandler'
export { default as Marker } from './components/layouts/Marker'
export {
  ModalTypes,
  ButtonCloseTypes,
  default as Modal
} from './components/layouts/Modal'
export { default as Section } from './components/layouts/Section'
export { default as Spinner } from './components/layouts/Spinner'
export { default as StepperLayout } from './components/layouts/StepperLayout'
export {
  default as CardVehicleSelector
} from './components/layouts/CardVehicleSelector'
export { default as CarSlider } from './components/layouts/CarSlider'
export {
  default as VehicleOptionsModal
} from './components/layouts/VehicleOptionsModal'
export { default as Breadcrumb } from './components/nav/Breadcrumb'
export { default as NavBar } from './components/nav/NavBar'
export { default as DisplayText } from './components/typography/DisplayText'
export { default as Heading } from './components/typography/Heading'
export { default as Calendar } from './components/forms/fields/Calendar'

// GoogleMapReact

export { default as Map } from './components/googleMap/GoogleMapReact'
export { default as Cluster } from './components/googleMap/Cluster'
export { default as DealerMarker } from './components/googleMap/DealerMarker'
export { default as UserMarker } from './components/googleMap/UserMarker'
export {
  default as DealerScreenCard
} from './components/googleMap/DealerScreenCard'
export { default as DealerCard } from './components/googleMap/DealerCard'
export {
  default as GeolocationInput
} from './components/googleMap/GeolocationInput'

// Helpers for Map
export {
  clusterPoints,
  extractDealerFromCluster,
  filterDealerByName,
  formatClusters
} from './components/googleMap/helpers'

// Validators
export { default as email } from './validators/email'
export { default as mileage } from './validators/mileage'
export { default as kilometer } from './validators/kilometer'
export { default as phone } from './validators/phone'
export { default as postalCode } from './validators/postalCode'
export { default as registration } from './validators/registration'
export { default as required } from './validators/required'
export { default as year } from './validators/year'
export { default as month } from './validators/month'
export { default as date } from './validators/date'

// Helpers form fields
export { formatedNumber } from './components/forms/fields/helpers'

// Hooks
export { default as useWindowWidth } from './hooks/useWindowWidth'
