import React from 'react'
import { createPortal } from 'react-dom'

import styles from './Spinner.module.scss'

interface Props {
  display?: boolean
}

export default function Spinner({ display = true }: Props) {
  if (!display) {
    return null
  }

  return createPortal(
    // tslint:disable-next-line:jsx-wrap-multiline
    <div className={styles.wrapper}>
      <div className={styles.spinner} />
    </div>,
    document.body
  )
}
