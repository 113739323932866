import React from 'react'
import ReactDOM from 'react-dom'

import Button, { ButtonTypes } from '../buttons/Button'
import CheckboxFieldArray from '../forms/fields/CheckboxFieldArray'
import Icon from '../icons'
import Heading from '../typography/Heading'
import styles from './VehicleOptionsModal.module.scss'

interface Option {
  label: string
  value: string
}

interface Props {
  buttonLabel: string
  display?: boolean
  text: string
  name: string
  options: Option[]
  onClick(): void
  onClose?(): void
}

export default function VehicleOptionsModal({
  display = true,
  buttonLabel,
  text,
  onClick,
  onClose,
  options,
  name
}: Props) {
  if (!display) {
    return null
  }

  return ReactDOM.createPortal(
    // tslint:disable-next-line:jsx-wrap-multiline
    <>
      <div className={styles.overlay} />
      <div className={styles.wrapper} aria-modal={true} role="dialog">
        <div className={styles.modal}>
          <div className={styles.header}>
            <button
              type="button"
              className={styles.closeButton}
              aria-label="close"
              onClick={onClose}
            >
              <span className={styles.closeIcon}>
                <Icon name="BottomArrow" />
              </span>
            </button>
            <Heading textUppercase>{text}</Heading>
          </div>

          <div className={styles.checkbox_group}>
            <CheckboxFieldArray
              name={name}
              options={options}
              styleType="line"
            />
          </div>
          <div>
            <Button
              types={ButtonTypes.PRIMARY}
              label={buttonLabel}
              onClick={onClick}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    </>,
    document.body
  )
}
