import React, { memo, useLayoutEffect, useRef } from 'react'

import cs from 'classnames'
import styles from './CardVehicleItem.module.scss'

export interface VehicleItemInformation {
  vehicleName: string
  vehicleDescription: string | number
  id: number
}

interface AlignObj {
  titleHeight?: number
  descHeight?: number
}

export interface Props {
  id: string | number
  picture: string
  title: string
  description: string
  title2nd?: string
  description2nd?: string
  colorInfo?: string
  isActive: boolean
  isDefault?: boolean
  onSelect: (id: string | number) => void
  alignObj?: AlignObj
  isLast?: boolean
  isAlign?: boolean
  setTitleHeight(titleHeight: number, descHeight: number, isLast: boolean): void
}

function CardVehicleItem({
  id,
  title,
  description,
  title2nd,
  description2nd,
  colorInfo,
  picture,
  onSelect,
  isActive,
  setTitleHeight,
  alignObj,
  isLast = false,
  isAlign = false
}: Props) {
  const titleRef = useRef<HTMLDivElement>(null)
  const descRef = useRef<HTMLDivElement>(null)
  const cardStyles = cs(styles.card, {
    [styles.active]: isActive
  })
  useLayoutEffect(() => {
    if (!isAlign) {
      return
    }
    if (titleRef && titleRef.current && descRef && descRef.current) {
      setTitleHeight(
        titleRef.current.offsetHeight,
        descRef.current.offsetHeight,
        isLast
      )
    }
  }, [])
  const titleStyles = {
    height: `${alignObj && alignObj.titleHeight}px`
  }

  const descStyles = {
    height: `${isAlign && alignObj && alignObj.descHeight}px`
  }
  return (
    <div className={cardStyles} role="button" onClick={() => onSelect(id)}>
      <div className={styles.card__img__wrapper}>
        <div className={styles.card__img__overlay} />
        <img src={picture} alt="vehicle" className={styles.card__img} />
      </div>
      <div className={styles.card__content}>
        <h2 className={styles.card__title} ref={titleRef} style={titleStyles}>
          {title}
          {title2nd && (
            <>
              <br />
              {title2nd}
            </>
          )}
        </h2>
        {description2nd && (
          <p
            className={styles.card__description_2nd}
            dangerouslySetInnerHTML={{ __html: description2nd }}
          />
        )}
        <p
          className={styles.card__description}
          dangerouslySetInnerHTML={{ __html: description }}
          ref={descRef}
          style={descStyles}
        />
        {colorInfo && (
          <p
            className={styles.card__description_color}
            dangerouslySetInnerHTML={{ __html: colorInfo }}
          />
        )}
      </div>
    </div>
  )
}

export default memo(CardVehicleItem)
