import * as Yup from 'yup'

import required from './required'

export default function email(message: string, requiredMessage?: string) {
  if (requiredMessage) {
    return required(requiredMessage).email(message)
  }

  return Yup.string().email(message)
}
