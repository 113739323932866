import cs from 'classnames'
import React, { ReactNode } from 'react'

import styles from './Heading.module.scss'

type Level = '1' | '2' | '3' | '4'

type Color = 'dark-gray' | 'gray' | 'white' | 'black'

interface Props {
  level?: Level
  ariaLevel?: Level
  children: ReactNode
  weight?: 'regular' | 'bold'
  color?: Color
  textUppercase?: boolean
  textAlign?: 'center' | 'left' | 'right'
}

export default function Heading({
  level = '1',
  ariaLevel = '1',
  weight = 'bold',
  color = 'gray',
  textAlign,
  children,
  textUppercase = false
}: Props) {
  const headingCSSClassnames = cs(styles['h' + level], [styles.default], {
    [styles.regular_weight]: weight === 'regular',
    [styles.color_dark_gray]: color === 'dark-gray',
    [styles.color_gray]: color === 'gray',
    [styles.color_white]: color === 'white',
    [styles.color_black]: color === 'black',
    [styles.uppercase]: textUppercase,
    [styles.text_align_center]: textAlign === 'center',
    [styles.text_align_left]: textAlign === 'left',
    [styles.text_align_right]: textAlign === 'right'
  })

  return React.createElement(
    'h' + level,
    { className: headingCSSClassnames, 'aria-level': ariaLevel },
    children
  )
}
