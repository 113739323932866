import { FieldProps } from 'formik'
import React, { ChangeEvent, useEffect } from 'react'
import { OptionsType, ValueType } from 'react-select/lib/types'

import find from 'lodash/find'
import CustomSelect from '../inputs/Select'
import CustomErrorMessage from './CustomErrorMessage'

interface Option {
  label: string
  value: string
}

interface Props extends FieldProps {
  options: OptionsType<Option>
  label: string
  isNullable?: boolean
}

export default function SelectField({
  form,
  field,
  options,
  label,
  isNullable,
  ...props
}: Props) {
  useEffect(() => {
    const isValueExist = !!find(
      options,
      option => option.value === form.values[field.name]
    )
    if (!isValueExist) {
      form.setFieldValue(field.name, isNullable ? null : '')
    }
  }, [form.values])

  const onChange = (option: ValueType<Option>) => {
    form.setFieldValue(field.name, (option as Option).value)
  }

  const getValue = () => {
    if (options) {
      return options.find(option => option.value === field.value)
    } else {
      return '' as any
    }
  }

  const handleMobileChange = (e: ChangeEvent<HTMLSelectElement>) => {
    form.setFieldValue(field.name, e.target.value)
  }

  const hasError = !!form.errors[field.name] && !!form.touched[field.name]

  return (
    <>
      <div style={{ position: 'relative' }}>
        <CustomSelect
          {...form}
          {...field}
          {...props}
          label={label}
          options={options}
          onChange={onChange}
          value={getValue()}
          hasError={hasError}
          handleMobileChange={handleMobileChange}
          // tslint:disable-next-line:jsx-no-lambda
          onBlur={() => form.setFieldTouched(field.name)}
        />
        <CustomErrorMessage name={field.name} />
      </div>
    </>
  )
}
