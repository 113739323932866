import { FieldProps } from 'formik'
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'

import cs from 'classnames'
import TextInput from '../inputs/TextInput'
import CustomErrorMessage from './CustomErrorMessage'
import {
  addSuffix,
  emailMatchingList,
  mileageNumberFormat,
  registrationNumberFormat,
  removeSuffix,
  upperFirstCharFormat
} from './helpers'
import styles from './TextField.module.scss'

interface Props extends FieldProps {
  label: string
  className?: string
  maxLength?: number
  required?: boolean
  registration?: boolean
  optionalMessage?: string
  mileage?: boolean
  email?: boolean
  upperFirstChar?: boolean
  mask?: string
  suffix?: string
  suggestion?: string[]
  width?: string
}

export default function TextField({
  form,
  field,
  label,
  registration = false,
  mask,
  mileage = false,
  upperFirstChar = false,
  suffix,
  suggestion = [],
  email = false,
  width,
  ...props
}: Props) {
  const hasError = !!form.errors[field.name] && !!form.touched[field.name]
  const isValid =
    (form.touched[field.name] || form.values[field.name]) &&
    !form.errors[field.name]
  const [focus, setFocus] = useState(false)
  const [autoComplete, setAutoComplete] = useState<'on' | 'off' | undefined>(
    undefined
  )
  const [isOpenSuggestion, showSuggestion] = useState(false)
  const [suggestionList, setSuggestionList] = useState(suggestion)
  const [activeSuggestion, setActiveSuggestion] = useState(0)
  const handleChange = useCallback(({ target }: SyntheticEvent) => {
    // limit the amount of chars in the field
    const { value } = target as HTMLInputElement
    const { maxLength } = props
    let msg = maxLength ? value.slice(0, maxLength) : value

    // format text if it is a registration
    if (registration) {
      msg = registrationNumberFormat(msg)
    }

    // format text if it is a mileage
    if (mileage) {
      msg = mileageNumberFormat(msg)
    }

    // format text if it is a name
    if (upperFirstChar) {
      msg = upperFirstCharFormat(msg)
    }
    if (suggestion.length && email) {
      const emailList = emailMatchingList(msg, suggestion)
      if (emailList.length) {
        setSuggestionList(emailList)
        setAutoComplete('off')
        showSuggestion(true)
      } else {
        setAutoComplete('on')
        showSuggestion(false)
      }
    }

    form.setFieldValue(field.name, msg)
  }, [])
  const handleFocus = useCallback((event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement
    let msg = value
    if (suffix) {
      msg = removeSuffix(msg, suffix)
    }
    form.setFieldValue(field.name, msg)
    setFocus(true)
  }, [])
  const handleBlur = useCallback((event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement
    let msg = value
    if (suffix) {
      msg = addSuffix(msg, suffix)
    }
    // for suggestion
    showSuggestion(false)
    setActiveSuggestion(-1)

    form.setFieldValue(field.name, msg)
    setFocus(false)
    form.handleBlur(event)
  }, [])

  useEffect(() => {
    if (field.value === suggestionList[0]) {
      showSuggestion(false)
    }
  }, [field.value])

  // for suggestion list only
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isOpenSuggestion) {
        return
      }
      // Esc
      if (event.keyCode === 27) {
        showSuggestion(false)
      }
      // Enter
      if (event.keyCode === 13 && suggestionList[activeSuggestion]) {
        form.setFieldValue(field.name, suggestionList[activeSuggestion])
        showSuggestion(false)
        if (!form.isValid) {
          event.preventDefault()
        }
        // arrow down
      } else if (event.keyCode === 40) {
        setActiveSuggestion(u => (!suggestionList[u + 1] ? 0 : u + 1))
        // arrow up
      } else if (event.keyCode === 38) {
        setActiveSuggestion(u =>
          !suggestionList[u - 1] ? suggestionList.length - 1 : u - 1
        )
      }
    },
    [suggestionList, activeSuggestion, form.isValid]
  )

  const onSuggestionMouseEnter = useCallback(
    index => {
      setActiveSuggestion(index)
    },
    [suggestionList]
  )

  const onClickSuggestion = useCallback(
    index => {
      form.setFieldValue(field.name, suggestionList[index])
    },
    [suggestionList]
  )

  const renderSuggestionList = (list: string[]) =>
    list.map((item: string, index: number) => {
      return (
        <li
          key={item}
          className={cs(styles.fieldSuggestion__item, {
            [styles.fieldSuggestion__item__active]: index === activeSuggestion
          })}
          onMouseEnter={() => onSuggestionMouseEnter(index)}
          //  use OnMouseDown instead of OnClick because onMouseDown happen before (onClick after) onBlur of the InputElement
          onMouseDown={() => onClickSuggestion(index)}
          role="link"
        >
          {item}
        </li>
      )
    })

  if (mask) {
    return (
      <InputMask
        mask={mask}
        maskChar={null}
        {...field}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {(inputProps: any) => (
          <TextInput
            label={label}
            {...form}
            {...props}
            hasError={!focus && hasError}
            isValid={isValid}
            {...inputProps}
          >
            {!focus && <CustomErrorMessage name={field.name} field="primary" />}
          </TextInput>
        )}
      </InputMask>
    )
  }

  return (
    <TextInput
      label={label}
      {...form}
      {...field}
      {...props}
      hasError={!focus && hasError}
      isValid={isValid}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      autoComplete={autoComplete}
      onKeyDown={suggestion.length ? handleKeyDown : undefined}
      width={width}
    >
      {isOpenSuggestion && (
        <ul className={styles.fieldSuggestion}>
          {renderSuggestionList(suggestionList)}
        </ul>
      )}
      {!focus && <CustomErrorMessage name={field.name} field="default" />}
    </TextInput>
  )
}
